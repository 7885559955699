const fasadOptions = [
  {
    value: 1,
    title: "Тонированное стекло",
  },
  {
    value: 2,
    title: "Матовое стекло",
  },
  {
    value: 3,
    title: "Стекла Stopsol (рефлективные)",
  },
  {
    value: 4,
    title: "Рифленое стекло",
  },
  {
    value: 5,
    title: "Зеркала",
  },
  {
    value: 6,
    title: "Матовые зеркала",
  },
  {
    value: 7,
    title: "Декоративная сетка в триплексе",
  },
  {
    value: 8,
    title: "Цветной триплекс",
  },
];

const isFasadOptions = ({ option }) => {
  let optionInfo = fasadOptions.find((e) => e.value === option);
  if (!optionInfo) {
    return "Опция не найдена";
  }

  return { option: optionInfo };
};

export { fasadOptions, isFasadOptions };
