import React from 'react';
import DoorMain from './DoorMain';

function PartitionMain({
  partitionWidth,
  partitionHeight,
  numDoors,
  handlePositions,
  handleHeight,
  topBottomBarHeight,
  plusProf,
  maxWidth,
  maxHeight,
  door,
  lineCount,
  colorProf,
  colorLine,
  fasad,
  xMargin,
  yMargin,
  mirrorState,
}) {
  let oneDoor = false
  if (numDoors == 1) {
    numDoors = 3;
    oneDoor = true
  }
  // Вычисление масштабного коэффициента
  const sideRectWidth = partitionWidth / numDoors;
  const grayBorderSize = 30
  topBottomBarHeight = 0
  const handleSize = 1000

  // Вычисление масштабного коэффициента с учетом боковых прямоугольников
  const maxScaleX = maxWidth / parseInt(partitionWidth);
  const maxScaleY = maxHeight / partitionHeight;
  const maxScale = Math.min(maxScaleX, maxScaleY);

  const doorWidth = partitionWidth / numDoors;
  const doorHeight = partitionHeight - 2 * topBottomBarHeight;

  const scaledHandleSize = handleSize * maxScale;
  const scaledDoorWidth = doorWidth * maxScale;
  const scaledDoorHeight = doorHeight * maxScale;
  const scaledHandleHeight = handleHeight * maxScale;
  const scaledHandleWidth = 5 * 10 * maxScale;
  const scaledTopBottomBarHeight = topBottomBarHeight * maxScale
  const scaledGrayBorderSize = grayBorderSize * maxScale

  const totalDoorsWidth = numDoors * scaledDoorWidth;
  const xOffset = (maxWidth - totalDoorsWidth) / 2; // Центрирование дверей
  function minusArray(arr, min = 1, max = 4) {
    if (!arr || arr.length === 0) {
      return arr;
    }

    const positions = arr.map(item => parseInt(item.position));
    const minPosition = Math.max(...positions); // Находим минимальное значение position
    let offset = 0;

    if (minPosition > 0) {
      offset = -minPosition - 1; // Вычисляем смещение, если минимальное position меньше 0
    }

    const hasZero = positions.includes(0);

    return arr.map(item => ({
      ...item,
      position: parseInt(item.position) + offset - (hasZero ? 1 : 0) // Применяем смещение и ограничиваем сверху значением max
    }));
  }
  function centerArray(arr, min = 1, max = 4) {
    if (!arr || arr.length === 0) {
      return arr;
    }

    const positions = arr.map(item => parseInt(item.position));
    const minPosition = Math.max(...positions); // Находим минимальное значение position
    let offset = 0;

    if (minPosition < -1) {
      offset = -minPosition - 1;
    }

    const hasZero = positions.includes(0);

    return arr.map(item => ({
      ...item,
      position: parseInt(item.position) + offset - (hasZero ? 1 : 0) // Применяем смещение и ограничиваем сверху значением max
    }));
  }

  if (door) {
    door = minusArray(door)
    door = centerArray(door)
    if (mirrorState == 1) {
      if (Array.isArray(door)) {
        door.reverse();
      }
    }
    if (mirrorState == 2) {
      door = door.map(item => ({
        ...item,
        position: -parseInt(item.position)// Применяем смещение и ограничиваем сверху значением max
      }));
      door = minusArray(door)
      door = centerArray(door)
    }
    if (mirrorState == 3) {
      door = door.map(item => ({
        ...item,
        position: -parseInt(item.position)// Применяем смещение и ограничиваем сверху значением max
      }));
      door = minusArray(door)
      door = centerArray(door)
      if (Array.isArray(door)) {
        door.reverse();
      }
    }
  }


  let val = [0, 0, 0, 0]
  let xval = [0, 0, 0, 0]
  if (lineCount > 1) {
    if (numDoors == 2) {
      val[1] = scaledGrayBorderSize / 2 + 2
      xval[1] = scaledGrayBorderSize / 2 + 2
    }
    if (numDoors == 3) {
      val[1] = scaledGrayBorderSize * 2 / 3 + 2
      xval[1] = scaledGrayBorderSize * 2 / 3 + 2
      val[2] = scaledGrayBorderSize * 2 / 3 + 2
      xval[2] = scaledGrayBorderSize * 2 / 3 + 2
    }
    if (numDoors == 4 && lineCount == 2) {
      val[0] = scaledGrayBorderSize / 2
      val[1] = scaledGrayBorderSize / 2
      val[2] = scaledGrayBorderSize / 2
      val[3] = scaledGrayBorderSize / 2
      xval[1] = scaledGrayBorderSize / 2
      xval[2] = 0
      xval[3] = scaledGrayBorderSize / 2
    }
    if (numDoors == 4 && lineCount == 4) {
      val[0] = scaledGrayBorderSize * 3 / 4
      val[1] = scaledGrayBorderSize * 3 / 4
      val[2] = scaledGrayBorderSize * 3 / 4
      val[3] = scaledGrayBorderSize * 3 / 4
      xval[1] = scaledGrayBorderSize / 2
      xval[2] = scaledGrayBorderSize
      xval[3] = 1.5 * scaledGrayBorderSize
    }
  }

  const doorIndex = door && door.length > 1 ? [...door].map((item, index) => ({ index: index, position: item.position })).sort((a, b) => b.position - a.position) : false;
  const doors = Array.from({ length: numDoors }, (_, i) => (
    <DoorMain
      oneDoor={oneDoor}
      colorProf={colorProf}
      i={doorIndex[i]?.index ?? i}
      key={doorIndex[i]?.index ?? i}
      x={xOffset + (doorIndex[i]?.index ?? i) * (scaledDoorWidth) - xval[doorIndex[i]?.index ?? i]}
      y={scaledTopBottomBarHeight}
      width={scaledDoorWidth + val[doorIndex[i]?.index ?? i]}
      height={scaledDoorHeight}
      handlePosition={oneDoor ? handlePositions && handlePositions[0] : handlePositions && handlePositions[doorIndex[i]?.index ?? i]}
      handleHeight={scaledHandleHeight}
      handleWidth={scaledHandleWidth}
      grayBorderSize={scaledGrayBorderSize}
      fasad={fasad && fasad[(doorIndex[i]?.index ?? i) + 1]}
      handleSize={scaledHandleSize}
      xMargin={xMargin}
      yMargin={yMargin}
    />

  ));

  if (doors.length > 0) {
    return (
      <div className="main-svg">
        <svg width={maxWidth} height={scaledDoorHeight + 2 * scaledTopBottomBarHeight + 40} viewBox={`0 0 ${maxWidth} ${scaledDoorHeight + 2 * scaledTopBottomBarHeight + 40}`}>
          <defs>
            <pattern id="lightHatch" patternUnits="userSpaceOnUse" width="20" height="20">
              <line x1="0" y1="0" x2="10" y2="10" stroke="lightgray" strokeWidth="1" />
            </pattern>
          </defs>
          {oneDoor ?
            <>
              {/* Верхняя полоса */}
              < rect x={xOffset + scaledDoorWidth} y="0" width={scaledDoorWidth} height={scaledTopBottomBarHeight} fill={colorLine ?? "gray"} stroke='black' />
              {/* Нижняя полоса */}
              <rect x={xOffset + scaledDoorWidth} y={scaledTopBottomBarHeight + scaledDoorHeight} width={scaledDoorWidth} height={scaledTopBottomBarHeight} fill={colorLine ?? "gray"} stroke='black' />
            </>
            :
            <>
              {/* Верхняя полоса */}
              < rect x={xOffset} y="0" width={totalDoorsWidth} height={scaledTopBottomBarHeight} fill={colorLine ?? "gray"} stroke='black' />
              {/* Нижняя полоса */}
              <rect x={xOffset} y={scaledTopBottomBarHeight + scaledDoorHeight} width={totalDoorsWidth} height={scaledTopBottomBarHeight} fill={colorLine ?? "gray"} stroke='black' />
            </>
          }

          {/* <rect x={0} y={0} width={maxWidth} height={scaledDoorHeight + 2 * scaledTopBottomBarHeight} fill="red" style={{ filter: 'opacity(0.4)' }} /> */}

          {doors}



        </svg>
      </div>
    );
  }
}

export default PartitionMain;
