import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { fasadOptions } from "../helpers/fasadOptions";
import { getImageURL } from '../helpers/image';

const ModalCategories = ({ showParam, setItem, item }) => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [index, setIndex] = useState("0");
  const groupedParams = showParam?.data?.params?.reduce((acc, item) => {
    const categoryValue = item.data?.category;
    const category = fasadOptions.find(option => option.value === categoryValue)?.title || 'Без категории';
    acc[category] = acc[category] || [];
    acc[category].push(item);
    return acc;
  }, {});

  // Создаем массив категорий в порядке, указанном в fasadOptions
  const sortedCategories = fasadOptions.map(option => option.title);

  // Добавляем "Без категории" в конец, если она есть
  if (groupedParams['Без категории']) {
    sortedCategories.push('Без категории');
  }

  // Создаем новый объект с отсортированными ключами
  const sortedGroupedParams = {};
  sortedCategories.forEach(category => {
    if (groupedParams[category]) {
      sortedGroupedParams[category] = groupedParams[category];
    }
  });

  useEffect(() => {
    if (!selectedItemId && item == 0) { //item не найден или не был передан
      const firstCategory = Object.keys(sortedGroupedParams)[0];
      const firstItem = sortedGroupedParams[firstCategory][0];
      setItem(showParam.data.params.findIndex(param => param.id === firstItem.id))
      setSelectedItemId(firstItem.id);
    }
    else if (!selectedItemId) {
      if (typeof item === 'number' && item >= 0 && showParam?.data?.params && item < showParam.data.params.length) {
        const selectedParamItem = showParam.data.params[item];
        if (selectedParamItem) {
          setSelectedItemId(selectedParamItem.id);
          let foundCategoryKey = 0;
          for (const categoryKey in sortedGroupedParams) {
            const itemsInCategory = sortedGroupedParams[categoryKey];
            const itemIndex = itemsInCategory.findIndex(groupedItem => groupedItem.id === selectedParamItem.id);
            if (itemIndex !== -1) {
              break;
            }
            foundCategoryKey++;
          }
          setIndex(String(foundCategoryKey))
        }
      }
      setSelectedItemId(showParam.data.params[item].id);
    }
    else {
      setItem(showParam.data.params.findIndex(param => param.id === selectedItemId))
    }


  }, [sortedGroupedParams, selectedItemId]);



  if (selectedItemId) {
    return (
      <Accordion defaultActiveKey={String(index)}>
        {Object.entries(sortedGroupedParams).map(([category, items], categoryIndex) => (
          <Accordion.Item eventKey={String(categoryIndex)} key={categoryIndex}>
            <Accordion.Header>{category}</Accordion.Header>
            <Accordion.Body>
              <div className="d-flex w-100 row row-cols-2 row-cols-lg-1 gx-1 gy-1 gx-lg-4 gy-lg-4">
                {items.map((item, index) => (
                  <Card
                    key={index}
                    className={`card-selector-param ${selectedItemId === item.id ? 'selected' : ''}`}
                    onClick={() => setSelectedItemId(item.id)}
                  >
                    <div className="mini">
                      <img src={getImageURL({ path: item?.media, type: "param", size: "max" })} alt="" />
                    </div>
                    <p>{item.title}</p>
                  </Card>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    );
  }
}

export default ModalCategories;

