import React from "react";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Footer from "./Footer";

const AdminMenu = () => {
  const role = useSelector((state) => state.auth.user?.role);
  const notification = useSelector((state) => state.notification);
  return (
    <div className="w-100">
      <Link
        to="/"
        className="fs-12 fw-7 p-2 py-3 d-flex align-items-center logo"
      >
        <img src="/logo.svg" className="w-100" />
        {/* <span className="ms-3 fw-8">RUSH2PLAY</span> */}
      </Link>
      <nav className="account-menu position-sticky top-1 mb-4">
        <ul>

          <li>
            <NavLink to="/users">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                  />
                  <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
                </svg>
                <span>Пользователи</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/order">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                  />
                  <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
                </svg>
                <span>Заказы</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/calcHistory">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                  />
                  <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
                </svg>
                <span>История</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/company">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Subtract"
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.77688 21.3755C2.93261 21.1957 2.36966 20.5161 2.28099 19.6575C2.14952 18.3844 2 16.0469 2 12C2 7.95305 2.14952 5.6156 2.28099 4.34251C2.36966 3.48389 2.93261 2.80424 3.77688 2.62451C5.1095 2.34081 7.6216 2 12 2C16.3784 2 18.8905 2.34081 20.2231 2.62451C21.0674 2.80424 21.6304 3.48389 21.719 4.34251C21.8505 5.6156 22 7.95305 22 12C22 16.0469 21.8505 18.3844 21.719 19.6575C21.6304 20.5161 21.0674 21.1957 20.2232 21.3755C18.8905 21.6592 16.3784 22 12 22C7.6216 22 5.1095 21.6592 3.77688 21.3755ZM18.1663 8.16665C18.1663 9.76795 17.263 11.1582 15.9382 11.8559C17.7133 12.5321 19.0685 14.1103 19.4876 16.0551C19.5454 16.3231 19.3977 16.5983 19.1278 16.6471C18.1554 16.8231 16.545 17 14.0006 17C11.4562 17 9.84581 16.8231 8.87341 16.6471C8.60246 16.5981 8.45446 16.3219 8.51251 16.0529C8.93216 14.1092 10.2869 12.5319 12.0612 11.856C10.7363 11.1584 9.83291 9.768 9.83291 8.16665C9.83291 5.8655 11.6984 4 13.9996 4C16.3008 4 18.1663 5.8655 18.1663 8.16665Z"
                    fill="#637381"
                  />
                  <path
                    id="shape"
                    d="M12.4382 14.8559C13.763 14.1583 14.6663 12.768 14.6663 11.1666C14.6663 8.8655 12.8008 7 10.4996 7C8.19841 7 6.33291 8.8655 6.33291 11.1666C6.33291 12.768 7.23626 14.1584 8.56116 14.856C6.78691 15.5319 5.43216 17.1092 5.01251 19.0529C4.95446 19.3219 5.10246 19.5981 5.37341 19.6471C6.34581 19.8231 7.95616 20 10.5006 20C13.045 20 14.6554 19.8231 15.6278 19.6471C15.8977 19.5983 16.0454 19.3231 15.9876 19.0551C15.5685 17.1103 14.2133 15.5321 12.4382 14.8559Z"
                    fill="#637381"
                  />
                </svg>

                <span>Компании</span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/members">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Subtract"
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.77688 21.3755C2.93261 21.1957 2.36966 20.5161 2.28099 19.6575C2.14952 18.3844 2 16.0469 2 12C2 7.95305 2.14952 5.6156 2.28099 4.34251C2.36966 3.48389 2.93261 2.80424 3.77688 2.62451C5.1095 2.34081 7.6216 2 12 2C16.3784 2 18.8905 2.34081 20.2231 2.62451C21.0674 2.80424 21.6304 3.48389 21.719 4.34251C21.8505 5.6156 22 7.95305 22 12C22 16.0469 21.8505 18.3844 21.719 19.6575C21.6304 20.5161 21.0674 21.1957 20.2232 21.3755C18.8905 21.6592 16.3784 22 12 22C7.6216 22 5.1095 21.6592 3.77688 21.3755ZM18.1663 8.16665C18.1663 9.76795 17.263 11.1582 15.9382 11.8559C17.7133 12.5321 19.0685 14.1103 19.4876 16.0551C19.5454 16.3231 19.3977 16.5983 19.1278 16.6471C18.1554 16.8231 16.545 17 14.0006 17C11.4562 17 9.84581 16.8231 8.87341 16.6471C8.60246 16.5981 8.45446 16.3219 8.51251 16.0529C8.93216 14.1092 10.2869 12.5319 12.0612 11.856C10.7363 11.1584 9.83291 9.768 9.83291 8.16665C9.83291 5.8655 11.6984 4 13.9996 4C16.3008 4 18.1663 5.8655 18.1663 8.16665Z"
                    fill="#637381"
                  />
                  <path
                    id="shape"
                    d="M12.4382 14.8559C13.763 14.1583 14.6663 12.768 14.6663 11.1666C14.6663 8.8655 12.8008 7 10.4996 7C8.19841 7 6.33291 8.8655 6.33291 11.1666C6.33291 12.768 7.23626 14.1584 8.56116 14.856C6.78691 15.5319 5.43216 17.1092 5.01251 19.0529C4.95446 19.3219 5.10246 19.5981 5.37341 19.6471C6.34581 19.8231 7.95616 20 10.5006 20C13.045 20 14.6554 19.8231 15.6278 19.6471C15.8977 19.5983 16.0454 19.3231 15.9876 19.0551C15.5685 17.1103 14.2133 15.5321 12.4382 14.8559Z"
                    fill="#637381"
                  />
                </svg>

                <span>Сотрудники</span>
              </span>
            </NavLink>
          </li>

          <li className="mt-4">
            <p className="nav-label">Контент</p>
          </li>

          <li>
            <NavLink to="/catalog">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M17.2798 4.5H6.7202C5.77169 4.5 5 5.06057 5 5.75042C5 6.43943 5.77169 7 6.7202 7H17.2798C18.2283 7 19 6.43943 19 5.75042C19 5.06054 18.2283 4.5 17.2798 4.5Z"
                  />
                  <path d="M17.2798 17H6.7202C5.77169 17 5 17.5606 5 18.2504C5 18.9394 5.77169 19.5 6.7202 19.5H17.2798C18.2283 19.5 19 18.9394 19 18.2504C19 17.5606 18.2283 17 17.2798 17Z" />
                  <path d="M17.2798 10.75H6.7202C5.77169 10.75 5 11.3106 5 12.0004C5 12.6894 5.77169 13.25 6.7202 13.25H17.2798C18.2283 13.25 19 12.6894 19 12.0004C19 11.3105 18.2283 10.75 17.2798 10.75Z" />
                </svg>
                <span>Каталог</span>
              </span>
            </NavLink>
          </li>


          <li>
            <NavLink to="/configurator">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M17.2798 4.5H6.7202C5.77169 4.5 5 5.06057 5 5.75042C5 6.43943 5.77169 7 6.7202 7H17.2798C18.2283 7 19 6.43943 19 5.75042C19 5.06054 18.2283 4.5 17.2798 4.5Z"
                  />
                  <path d="M17.2798 17H6.7202C5.77169 17 5 17.5606 5 18.2504C5 18.9394 5.77169 19.5 6.7202 19.5H17.2798C18.2283 19.5 19 18.9394 19 18.2504C19 17.5606 18.2283 17 17.2798 17Z" />
                  <path d="M17.2798 10.75H6.7202C5.77169 10.75 5 11.3106 5 12.0004C5 12.6894 5.77169 13.25 6.7202 13.25H17.2798C18.2283 13.25 19 12.6894 19 12.0004C19 11.3105 18.2283 10.75 17.2798 10.75Z" />
                </svg>
                <span>Конфигуратор</span>
              </span>
            </NavLink>
          </li>

        </ul>
        <Footer />
      </nav>
    </div>
  );
};

export default AdminMenu;
