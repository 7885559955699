import React from 'react';

const DoorTop = ({ x, y, width, height, grayStripeWidth, lineWidth = 1, }) => {

  // Проверки на корректность входных данных
  if (grayStripeWidth * 2 >= width || lineWidth >= width) {
    console.error("grayStripeWidth * 2 or lineWidth cannot be greater than or equal to width");
    return null;
  }

  const mainRectWidth = width - 2 * grayStripeWidth;
  const lineYRect = (height - lineWidth) / 2;

  return (
    <g transform={`translate(${x}, ${y})`}>
      {/* Левая серая полоска */}
      <rect x={0} y={0} width={grayStripeWidth} height={height} fill="lightgray" stroke="black" />

      {/* Центральный прямоугольник */}
      <rect x={grayStripeWidth} y={0} width={mainRectWidth} height={height} fill="white" stroke="black" />

      {/* Тонкая линия */}
      <rect x={grayStripeWidth} y={lineYRect} width={mainRectWidth} height={lineWidth} fill="black" />

      {/* Правая серая полоска */}
      <rect x={grayStripeWidth + mainRectWidth} y={0} width={grayStripeWidth} height={height} fill="lightgray" stroke="black" />
    </g>
  );
};

export default DoorTop;
