// value - цена, currency - выводить валюту (true|false))
const customPrice = (value, currency = true) => {
  if (!value) {
    return 0 + " ₽";
  }
  if (currency) {
    value = (
      <>
        {Number(value).toLocaleString('ru-RU', {
          style: 'currency',
          currency: 'RUB',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}
      </>
    );
  }
  return value;
};

const convertWeight = (value) => {
  if (!value) {
    return 0 + "\u00A0г";
  }
  if (value < 0.1) {
    value = value * 1000;
  }
  if (value > 999999) {
    value = (value / 1000000).toLocaleString() + "\u00A0т";
  } else if (value > 999) {
    value = (value / 1000).toLocaleString() + "\u00A0кг";
  } else {
    value = value.toLocaleString() + "\u00A0г";
  }
  return value;
};
const kkal = (data) => {
  var protein = 0;
  var fat = 0;
  var carbohydrate = 0;
  var weight = 0;

  if (data?.length > 0) {
    data.forEach((e) => {
      let energy = e?.ingredient?.energy;
      if (energy && energy?.carbohydrate && energy?.protein && energy?.fat) {
        fat += 9.0 * Number(energy.fat);
        carbohydrate += 3.75 * Number(energy.carbohydrate);
        protein += 4.0 * Number(energy.protein);
        weight += Math.round(Number(e.weight));
      }
    });

    var kkal = Math.round(fat + carbohydrate + protein);
    var kkalAll = Math.round(
      Number(weight) * ((fat + carbohydrate + protein) / 100)
    );
  }
  return { kkal, protein, fat, weight, kkalAll, carbohydrate };
};

export { customPrice, kkal, convertWeight };
