import React, { useCallback, useLayoutEffect, useState } from "react";
import { Card, Col, Row, Container, Form } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import { roles } from "../../helpers/member";
import { createMember } from "../../services/member";
import { titles } from "../../helpers/titles";
import { getCompanyAll } from "../../services/company";

const MemberCreate = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [company, setCompany] = useState({
    loading: true,
    items: [],
  });
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  useLayoutEffect(() => {
    getCompanyAll()
      .then(
        (res) =>
          res &&
          setCompany((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setCompany((prev) => ({ ...prev, loading: false })));
  }, []);

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    createMember(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      )
      .finally(() => setBtnLoading(false));
  }, []);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;

    if (event.target.checked) {
      // Добавляем значение в массив, если чекбокс отмечен
      setValue("taskTitle", [...(form?.taskTitle || []), value]);
    } else {
      // Удаляем значение из массива, если чекбокс снят
      setValue("taskTitle", (form?.taskTitle || []).filter((item) => item !== value));
    }
  };

  return (
    <Container fluid={true}>
      <Meta title="Добавление сотрудника" />
      <section className="box">
        <h3 className="mb-4">Добавление сотрудника</h3>
        <Card body>
          <Row>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Email"
                  name="email"
                  placeholder="Введите email"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Номер телефона"
                  name="phone"
                  placeholder="+7(000)000-00-00"
                  mask="+7(999)999-99-99"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  type="date"
                  label="День рождения"
                  placeholder="Введите день рождения (Необязательно)"
                  name="brithday"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Фамилия"
                  name="lastName"
                  placeholder="Введите фамилию (Необязательно)"
                  errors={errors}
                  register={register}
                  validation={{
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Имя"
                  name="firstName"
                  errors={errors}
                  register={register}
                  placeholder="Введите имя"
                  validation={{
                    required: "Введите имя",
                    maxLength: {
                      value: 20,
                      message: "Максимально 20 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Отчество"
                  name="patronymic"
                  errors={errors}
                  register={register}
                  placeholder="Введите отчество (Необязательно)"
                  validation={{
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Select
                  className="mb-4"
                  label="Организация"
                  onClick={(e) => setValue("companyId", e.value)}
                  value={form.companyId}
                  data={company?.items.map((e) => ({ value: e.id, data: e, title: e.title }))}
                />

              </div>
            </Col>
            <Col md={4}>
              <Select
                className="mb-4"
                label="Роль"
                onClick={(e) => setValue("role", e.value)}
                value={form.role}
                data={roles.slice(1, 2)}
              />
            </Col>
            <Col md={4}>
              <Select
                className="mb-4"
                label="Пол"
                onClick={(e) => setValue("sex", e.value)}
                value={form.sex ?? ""}
                data={[
                  { title: "Не указано", value: "" },
                  { title: "Мужской", value: "man" },
                  { title: "Женский", value: "woman" },
                ]}
              />
            </Col>
          </Row>
          <h5 className="mb-4">Задать пароль</h5>
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  label="Пароль"
                  name="password"
                  errors={errors}
                  placeholder="Введите пароль"
                  register={register}
                  validation={{
                    maxLength: {
                      value: 200,
                      message: "Максимально 200 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  label="Повторный пароль"
                  name="confirmPassword"
                  errors={errors}
                  placeholder="Введите повторно пароль"
                  register={register}
                  validation={{
                    maxLength: {
                      value: 200,
                      message: "Максимально 200 символов",
                    },
                  }}
                />
              </div>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-primary"
              isValid={isValid}
              isLoading={btnLoading}
              disabled={btnLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить изменения
            </Button>
          </div>
        </Card>
      </section>
    </Container>
  );
};

export default MemberCreate;
