import React, { useRef, useEffect, useState } from 'react';

const SizeLineHorizontal = ({ x, y, height, width, text, size, textAboveLine, lineLength1, lineLength2 }) => {

  const halfHeight = 15;
  const arrowSize = 5;
  const textRef = useRef(null);
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.offsetWidth);
    }
  }, [text]);

  const textYOffset = (textAboveLine ? -10 : 10) // Смещение текста с учетом длины линии
  const textXOffset = width / 2;

  const line1Y1 = textAboveLine ? -halfHeight : halfHeight;
  const line1Y2 = textAboveLine ? lineLength1 : halfHeight - lineLength1 - 5;
  const line2Y1 = textAboveLine ? -halfHeight : halfHeight;
  const line2Y2 = textAboveLine ? lineLength2 : halfHeight - lineLength2 - 5;

  const arrowY = 0;


  return (
    <g transform={`translate(${x}, ${y})`}>
      {/* Горизонтальная линия */}
      <line
        x1={0}
        y1={0}
        x2={width}
        y2={0}
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />

      <polygon
        points={`${arrowSize},${arrowY - arrowSize} ${arrowSize},${arrowY + arrowSize} 0,${arrowY}`}
        fill="black"
      />
      <polygon
        points={`${width - arrowSize},${arrowY - arrowSize} ${width - arrowSize},${arrowY + arrowSize} ${width},${arrowY}`}
        fill="black"
      />

      {/* Вертикальные линии */}
      <line
        x1={0}
        y1={line1Y1}
        x2={0}
        y2={line1Y2}
        stroke="black"
        strokeWidth="1"
      // strokeDasharray="5,5"
      />
      <line
        x1={width}
        y1={line2Y1}
        x2={width}
        y2={line2Y2}
        stroke="black"
        strokeWidth="1"
      // strokeDasharray="5,5"
      />

      {/* Текст */}
      {text && (
        <text
          x={textXOffset}
          y={textYOffset}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={size}
        >
          <tspan ref={textRef}>{text}</tspan>
        </text>
      )}
    </g>
  );
};

export default SizeLineHorizontal;
