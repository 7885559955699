import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import { editCompany, getCompany } from "../../services/company";
import DragDropFile from "../../components/DragDropFile";
import { getImageURL } from "../../helpers/image";
import ImageCropper from "../../components/Cropper";

const EditCompany = () => {
  const { companyId } = useParams();
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const {
    control,
    register,
    reset,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  const getData = () => {
    getCompany(companyId)
      .then((res) => reset(res))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const [image, setImage] = useState({
    show: false,
    data: false,
  });

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    editCompany(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      )
      .finally(() => {
        setBtnLoading(false)
        navigate(-1)
      })
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!form?.id) {
    return (
      <>
        <Meta title="Редактировать компанию" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Такой компании не существует</h3>
        </Info>
      </>
    );
  }

  return (
    <Container fluid={true}>
      <Meta title="Редактировать компанию" />
      <section className="box">
        <h3 className="mb-4">Редактировать компанию</h3>
        <Card body>
          <Row>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Название"
                  name="title"
                  placeholder="Введите название (Необязательно)"
                  errors={errors}
                  register={register}
                  validation={{
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>

            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Номер телефона"
                  name="phone"
                  value={form?.phone}
                  placeholder="+7(000)000-00-00"
                  mask="+7(999)999-99-99"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Адрес"
                  name="adress"
                  value={form?.adress}
                  placeholder="Введите адрес"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={3} className="mb-4">
              <DragDropFile
                file={form?.media ?? getImageURL({ path: form?.logo, type: "company", size: "max" })}
                onChange={(e) =>
                  setImage((info) => ({
                    show: !info.show,
                    data: e,
                  }))
                }
              />
              <ImageCropper
                file={image.data[0]}
                show={image.show}
                aspect={40 / 16}
                setShow={(e) =>
                  setImage((info) => ({ ...info, show: e }))
                }
                onComplete={(e) => {
                  e && setValue("file", e.file);
                  e && setValue("media", e.blob);
                }}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-primary"
              isValid={isValid}
              isLoading={btnLoading}
              disabled={btnLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить изменения
            </Button>
          </div>
        </Card>
      </section>
    </Container>
  );
};

export default EditCompany;
