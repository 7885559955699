import React, { useState } from 'react';

function Door({ x, y, width, height, handlePosition, handleHeight, grayBorderSize, title, onChange, fasad, handleSize }) {
  const borderWidth = 1; // Ширина черной границы

  const innerWidth = width - 2 * grayBorderSize - 2 * borderWidth;
  const innerHeight = height - 2 * grayBorderSize - 2 * borderWidth;
  const [hovered, setHovered] = useState(false);

  const handleMouseOver = () => setHovered(true);
  const handleMouseOut = () => setHovered(false);

  const fillColor = hovered ? 'pink' : (fasad ? "lightgray" : 'white');
  const handleY = height - handleSize - handleHeight / 2;
  const handles = (() => {
    if (handlePosition?.left && handlePosition?.right) {

      return [

        <rect
          key="left"
          x={grayBorderSize + borderWidth}
          y={handleY}
          width={grayBorderSize}
          height={handleHeight}
          stroke={"black"}
          strokeWidth={borderWidth}
          fill={"lightgray"}
        />,
        <rect
          key="right"
          x={width - 2 * grayBorderSize - borderWidth}
          y={handleY}
          width={grayBorderSize}
          height={handleHeight}
          stroke={"black"}
          strokeWidth={borderWidth}
          fill={"lightgray"}
        />,
      ];
    } else if (handlePosition?.left) {
      return [
        <rect
          key="left"
          x={grayBorderSize + borderWidth}
          y={handleY}
          width={grayBorderSize}
          height={handleHeight}
          stroke={"black"}
          strokeWidth={borderWidth}
          fill={"lightgray"}
        />,
      ];
    } else if (handlePosition?.right) {
      return [
        <rect
          key="right"
          x={width - 2 * grayBorderSize - borderWidth}
          y={handleY}
          width={grayBorderSize}
          height={handleHeight}
          stroke={"black"}
          strokeWidth={borderWidth}
          fill={"lightgray"}
        />,
      ];
    } else {
      return []; // Или верните null, если handlePosition некорректен
    }
  })();

  return (
    <g transform={`translate(${x}, ${y})`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={onChange} className='pointer'>
      <defs>
        <pattern id="glass-pattern" patternUnits="userSpaceOnUse" width="10" height="10">
          <line x1="0" y1="0" x2="10" y2="10" stroke="lightgray" fill="white" strokeWidth="2" strokeDasharray="2 2" />
          <line x1="0" y1="10" x2="10" y2="0" stroke="lightgray" strokeWidth="2" fill="white" strokeDasharray="2 2" />
        </pattern>
      </defs>
      {/* <rect x={0} y={0} width={width} height={height} fill="none" stroke="black" strokeWidth={borderWidth} /> */}

      {/* Серый профиль (полностью залитый) */}
      <rect
        x={borderWidth}
        y={borderWidth}
        width={width - 2 * borderWidth}
        height={height - 2 * borderWidth}
        stroke="black"
        fill="lightgray"
      />

      {/* Прозрачный внутренний прямоугольник */}
      <rect
        x={grayBorderSize}
        y={grayBorderSize}
        width={innerWidth}
        height={innerHeight}
        fill={fillColor}

      />

      {/* Внутренняя стеклянная вставка (убрана, т.к. она заменена прозрачным прямоугольником) */}
      <rect
        x={borderWidth + grayBorderSize}
        y={borderWidth + grayBorderSize}
        width={innerWidth}
        height={innerHeight}

        fill="url(#glass-pattern)"
        stroke='black'
      />
      {(title || fasad) &&
        <foreignObject x={1.5 * grayBorderSize} y={height / 3} width={width - 3 * grayBorderSize} height="100%"> {/* Увеличиваем высоту */}
          <div xmlns="http://www.w3.org/1999/xhtml" style={{ backgroundColor: 'white', padding: '10px', lineHeight: '1.2em', textAlign: 'center', width: '100%', boxSizing: 'border-box', borderRadius: "3em" }}> {/* Добавляем стили */}
            {fasad?.title ?? title}
          </div>
        </foreignObject>
      }
      {handlePosition && !handlePosition.without && handles}
    </g >
  );
}

export default Door;
