import { memo, useState, useRef, useEffect } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import InputMask from "react-input-mask";

const NumberPanel = ({ onNumberClick, onBackspaceClick, onClearClick }) => {
  return (
    <div className="number-panel">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
        <button key={number} onClick={() => onNumberClick(number)}>
          {number}
        </button>
      ))}
      <button className="clear-btn" onClick={onClearClick}>C</button>
      <button onClick={() => onNumberClick(0)}>0</button>
      <button onClick={onBackspaceClick}>←</button>
    </div>
  );
};

const Input = memo(
  ({
    mask,
    name,
    label,
    placeholder,
    defaultValue,
    value,
    className,
    register,
    errors,
    min,
    max,
    maxLength = 5000,
    autofocus,
    required,
    readOnly = true,
    type,
    onChange,
    onKeyDown,
    onClick,
    validation,
    rightIcon = false,
    rightIconClick,
    error,
    numberPanel = false,
  }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showNumberPanel, setShowNumberPanel] = useState(false);
    const inputRef = useRef(null); // Ref для инпута
    const panelRef = useRef(null); // Ref для панели

    const handleNumberClick = (number) => {
      const newValue = value ? `${value}${number}` : `${number}`;
      onChange && onChange(newValue);
    };

    const handleBackspaceClick = () => {
      const newValue = value ? value.slice(0, -1) : "";
      onChange && onChange(newValue);
    };

    const handleClearClick = () => {
      onChange && onChange(""); // Очищаем значение инпута
    };

    // Закрытие панели при клике вне инпута или панели
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          inputRef.current &&
          !inputRef.current.contains(event.target) &&
          panelRef.current &&
          !panelRef.current.contains(event.target)
        ) {
          setShowNumberPanel(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div
        className={
          "input " +
          className +
          (((errors && errors[name]?.type === "required") || error) ? " error" : "") +
          (type == "password" || rightIcon ? " input-password" : "")
        }
        ref={inputRef} // Привязываем ref к контейнеру инпута
        onMouseEnter={() => numberPanel && setShowNumberPanel(true)} // Показ панели при наведении
        onMouseLeave={() => numberPanel && setShowNumberPanel(false)} // Скрытие панели при уходе курсора
      >
        <div>
          {label && (
            <label htmlFor={name} className="label">
              {label}
              {required && "*"}
            </label>
          )}
          {mask ? (
            <InputMask
              mask={mask}
              autoFocus={autofocus}
              id={name}
              name={name}
              autoComplete="no"
              type={type}
              maxLength={maxLength}
              min={min}
              max={max}
              placeholder={placeholder}
              defaultValue={defaultValue}
              value={value}
              onChange={(e) =>
                onChange && !register && onChange(e.target.value)
              }
              onFocus={() => numberPanel && setShowNumberPanel(true)} // Показ панели при фокусе
              onClick={(e) => {
                onClick && onClick(e);
                numberPanel && setShowNumberPanel(true);
              }}
              onKeyDown={(e) => onKeyDown && !register && onKeyDown(e.key)}
              readOnly={!readOnly && "readonly"}
              {...(register && { ...register(name, validation) })}
            ></InputMask>
          ) : (
            <input
              autoFocus={autofocus}
              id={name}
              name={name}
              autoComplete="no"
              type={showPassword ? "text" : type}
              maxLength={maxLength}
              min={min}
              max={max}
              placeholder={placeholder}
              defaultValue={defaultValue}
              value={value}
              onChange={(e) =>
                onChange && !register && onChange(e.target.value)
              }
              onFocus={() => numberPanel && setShowNumberPanel(true)} // Показ панели при фокусе
              onClick={(e) => {
                onClick && onClick(e);
                numberPanel && setShowNumberPanel(true);
              }}
              onKeyDown={(e) => onKeyDown && !register && onKeyDown(e.key)}
              readOnly={!readOnly && "readonly"}
              {...(register && { ...register(name, validation) })}
            />
          )}
          {type == "password" && (
            <div className="input-password-eye text-muted">
              <a onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? (
                  <IoEyeOffOutline size={22} />
                ) : (
                  <IoEyeOutline size={22} />
                )}
              </a>
            </div>
          )}
          {rightIcon && (
            <div className="input-password-eye text-muted">
              <a onClick={() => rightIconClick && rightIconClick()}>
                {rightIcon()}
              </a>
            </div>
          )}
        </div>
        {errors && errors[name]?.type === "required" && (
          <p className="text-left error-text fs-07">{errors[name]?.message}</p>
        )}
        {numberPanel && showNumberPanel && (
          <div className="number-panel-wrapper" ref={panelRef}>
            <NumberPanel
              onNumberClick={handleNumberClick}
              onBackspaceClick={handleBackspaceClick}
              onClearClick={handleClearClick}
            />
          </div>
        )}
      </div>
    );
  }
);

export default Input;