import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";
import Meta from "../../components/Meta";
import Loader from "../../components/UI/Loader";
import { useForm, useWatch } from "react-hook-form";
import Select from "../../components/UI/Select";
import { getCategories, getCategory } from "../../services/category";
import { getImageURL } from "../../helpers/image";
import Input from "../../components/UI/Input";
import Partition from "../../components/Partition";
import CustomModal from "../../components/utils/CustomModal";
import PartitionTop from "../../components/PartitionTop";
import { NotificationManager } from "react-notifications";
import useIsMobile from "../../hooks/useIsMobile";
import PartitionMain from "../../components/PartitionMain";
import ModalCategories from "../../components/ModalCategories";
import * as math from 'mathjs';
import { PiWarningCircleBold } from "react-icons/pi";
import Button from "../../components/UI/Button";
import { customPrice } from "../../helpers/product";
import Users from "../user";
import UserCreate from "../user/Create";
import { createCalc, getCalc } from "../../services/calc";
import { useNavigate, useParams } from "react-router-dom";
import ImagePreview from "../../components/ImagePreview";
import html2canvas from 'html2canvas';

const EditCategory = () => {
  const { id } = useParams();
  const mainImgRef = useRef(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [catalog, setCatalog] = useState({ items: [], loading: true });
  const [calc, setCalc] = useState({});
  const [user, setUser] = useState({});
  const [show, setShow] = useState({
    show: false,
    data: false,
  });
  const [showParam, setShowParam] = useState({
    show: false,
    data: false,
  });
  const [showUser, setShowUser] = useState({
    show: false,
    data: false,
  });
  const navigate = useNavigate();

  const mirrorStates = [0, 1, 2, 3]; // Возможные состояния
  const [mirrorState, setMirrorState] = useState(0);

  const handleMirrorToggle = () => {
    setMirrorState((prevState) => {
      const nextIndex = (prevState + 1) % mirrorStates.length;

      // Создаем копию данных, чтобы не изменять оригинал
      const newData = { ...data };
      const newDoor = [...newData.door];
      const newRol = [...newData.rol];

      // Обрабатываем каждую ручку
      for (let i = 0; i < newDoor.length; i++) {
        const doorItem = newDoor[i];
        if (doorItem && (doorItem.left || doorItem.right)) {
          // Меняем местами left и right
          const temp = doorItem?.left ?? false;
          doorItem.left = doorItem.right ?? false;
          doorItem.right = temp;
        }
      }

      // Обновляем состояние с новыми данными
      setValue("door", newDoor.reverse());
      setValue("rol", newRol.reverse());

      return mirrorStates[nextIndex];
    });
  };

  const isMobileXL = useIsMobile("1639px");
  const isMobileLG = useIsMobile("1530px");
  const isMobileMD = useIsMobile("1440px");
  const isMobileSM = useIsMobile("1300px");
  const isMobileXS = useIsMobile("1100px");
  const maxWidth = isMobileXS ? 350 : isMobileSM ? 400 : isMobileMD ? 500 : isMobileLG ? 550 : isMobileXL ? 615 : 700;
  const maxWidthMain = isMobileXS ? 225 : isMobileSM ? 256 : isMobileMD ? 320 : isMobileLG ? 370 : isMobileXL ? 392 : 409;

  const xMargin = isMobileXS ? 62.5 : isMobileSM ? 72 : isMobileMD ? 91 : isMobileLG ? 105 : isMobileXL ? 115 : 115;
  const yMargin = isMobileXS ? 9 : isMobileSM ? 10 : isMobileMD ? 16 : isMobileLG ? 16 : isMobileXL ? 16 : 16;

  const maxHeight = maxWidth / 2;
  const [selectedItemId, setSelectedItemId] = useState(null);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });


  const getData = useCallback(() => {
    getCategories()
      .then((res) => {
        setCatalog((prev) => ({
          ...prev,
          items: res,
          loading: false
        }));

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false)
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при загрузке"
        );
      });
  }, []);
  const getCalcData = useCallback((id) => {
    getCalc(id)
      .then((res) => {
        reset(res.data)
        setCalc(res.calc)
        setUser(res.user)
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при загрузке"
        );
      });
  }, []);

  useEffect(() => {
    if (id) {
      getCalcData(id);
    }
    getData();

  }, []);

  const data = useWatch({ control });
  useEffect(() => {
    const calculatePrice = (paramIndex, multiplier, defaultValue = 0) => {
      const param = data?.param?.[paramIndex];
      return param ? param.price * multiplier : defaultValue;
    };

    if (data.param) {
      const doorCount = data?.param[5]?.data?.doorCount ?? 0;
      const lineCount = data?.param[5]?.data?.lineCount ?? 0;
      const rolCount = data?.param[9]?.data?.rolCount ?? 0;
      const plusProf = parseInt(data?.param[5]?.data?.plusProf) ?? 0;
      const barHeight = parseInt(data?.param[6]?.data?.height) ?? 0;
      const lineFormula = data?.param[5]?.data?.lineFormula ?? 0;
      let limitHeight
      if (data.height) {
        if (parseInt(data.height - barHeight - 20) >= 1900 && parseInt(data.height - barHeight - 20) <= 3200) {
          limitHeight = false
        }
        else {
          limitHeight = true
        }
      }
      let limitWidth
      if (data.width) {
        if (parseInt(data.width / doorCount + plusProf) >= 450 && parseInt(data.width / doorCount + plusProf) <= 1600) {
          limitWidth = false
        }
        else {
          limitWidth = true
        }
      }
      const height = (data.height && !limitHeight) ? (data.height ?? 0) : (data?.param[5]?.data?.defaultHeight ?? 0);
      const width = (data.width && !limitWidth) ? data.width : data?.param[5]?.data?.dafaultWidth ?? 0;

      const doorHeight = parseInt(height - barHeight - 20) > 0 ? parseInt(height - barHeight - 20) : 0;
      const doorWidth = parseInt(width / doorCount + plusProf) || 0; // Обработка деления на ноль

      let xPrice = 0 + (doorHeight > 2550 ? doorHeight > 3000 ? 10 : 5 : 0) + (doorWidth > 1000 ? doorWidth > 1300 ? doorWidth > 1500 ? 15 : 10 : 5 : 0)

      const priceProf = calculatePrice(1, doorCount * 2 * (doorHeight + doorWidth) / 1000);
      const priceProfOne = calculatePrice(1, 2 * (doorHeight + doorWidth) / 1000)
      let lineWidth = []
      let sumLineWidth = 0

      for (let i = 0; i < lineCount; i++) {
        if (lineFormula[i]) {
          const compiledFormula = math.compile(lineFormula[i]);
          lineWidth[i] = parseInt(compiledFormula.evaluate({ x: width }));
          sumLineWidth += lineWidth[i];
        }
      }

      let priceFasad = 0
      let door = []

      for (let i = 0; i < doorCount; i++) {
        if (data?.fasad && data.fasad[i + 1]) {
          priceFasad += data.fasad[i + 1]?.price * doorHeight / 1000 * doorWidth / 1000 * (100 + xPrice) / 100;
          door[i] = (data.fasad[i + 1]?.price * doorHeight / 1000 * doorWidth / 1000 * (100 + xPrice) / 100) + priceProfOne
        }
        else {
          priceFasad += calculatePrice(2, doorHeight / 1000 * doorWidth / 1000 * (100 + xPrice) / 100);
          door[i] = calculatePrice(2, doorHeight / 1000 * doorWidth / 1000 * (100 + xPrice) / 100) + priceProfOne
        }
      }


      calculatePrice(2, doorCount * doorHeight / 1000 * doorWidth / 1000);
      const priceLine = calculatePrice(8, sumLineWidth / 1000);
      let priceRol = 0
      for (let i = 0; i < data?.rol?.length; i++) {
        if (data?.rol[i]?.price) {
          priceRol += data?.rol[i]?.price;
          door[i] += data?.rol[i]?.price;
        }
      }
      const priceDekor = calculatePrice(3, doorHeight / 1000 * doorWidth / 1000);

      const leftHandles = data?.door && data?.door.filter(door => door?.left).length;
      const rightHandles = data?.door && data?.door.filter(door => door?.right).length;
      if (data?.door) {
        for (let i = 0; i < doorCount; i++) {
          if (data.door[i] && (data.door[i]?.left || data.door[i]?.right)) {
            door[i] += calculatePrice(4, 1)
          }
        }
      }
      const totalHandles = leftHandles + rightHandles;

      const priceHand = calculatePrice(4, totalHandles);
      const total = Math.ceil(priceProf + priceFasad + priceLine + priceRol + priceDekor + priceHand);

      setCalc({
        height,
        width,
        doorHeight,
        doorWidth,
        priceProf,
        priceFasad,
        priceLine,
        priceRol,
        priceDekor,
        priceHand,
        total,
        lineWidth,
        totalHandles,
        limitHeight,
        limitWidth,
        door,
        xPrice
      });
    }
  }, [data]);
  const onShow = useCallback((item) => {
    if (!data?.param || !data?.param[6]) {
      return NotificationManager.error("Выберите тип крепления");
    }



    let filtredItem = { ...item }; // Создаем копию, чтобы не мутировать исходный объект
    filtredItem.params = (data?.param[6] && data?.param[6].id) ? // Проверка на существование id
      filtredItem.params
        .filter(e => e.data.type.includes(data.param[6].id))
        .sort((a, b) => a.priority - b.priority)
      : []; // Если id не существует, params будет пустым массивом.

    if (filtredItem.params.length === 0) { // Исправлено: проверка длины params
      return NotificationManager.error("Для выбранного типа крепления отсутствуют схемы");
    }
    if (data.param[5]) {
      const index = filtredItem.params.findIndex(item => item.id === data.param[5].id);
      setSelectedItemId(index === -1 ? 0 : index);
    } else {
      setSelectedItemId(0);
    }
    setShow({ show: true, data: filtredItem }); // Отправляем только params
  }, [data, setSelectedItemId, setShow]); // param добавлен в зависимости, если setValue использует param
  const onParam = useCallback((item) => {
    setValue(`param.${item.categoryId}`, item)
  }, [data, setValue, reset]); // param добавлен в зависимости, если setValue использует param

  const onShowParam = useCallback(({ item, index }) => {
    const sortedParams = [...item.params].sort((a, b) => a.priority - b.priority);
    const params = { ...item, params: sortedParams };

    let selectedIndex = -1;
    if (data.param && data.param[item.id]) {
      selectedIndex = sortedParams.findIndex(paramItem => paramItem.id === data.param[item.id].id);
    }
    setSelectedItemId(selectedIndex === -1 ? 0 : selectedIndex);
    setShowParam({ show: true, data: params, index: index });
  }, [data, setShowParam, setSelectedItemId]);

  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], filename, { type: mime });
  };



  const onSave = useCallback(async (status) => {
    try {
      setLoadingFile(true)
      const media = [];

      // 1. Захват main-img
      const mainImgCanvas = await html2canvas(mainImgRef.current, {
        useCORS: true,
        scale: 2,
        logging: true,
        allowTaint: false,
        foreignObjectRendering: false,
      });
      const mainImgDataURL = mainImgCanvas.toDataURL('image/webp', 0.9);
      media.push(dataURLtoFile(mainImgDataURL, 'main-image.webp'));

      // 2. Захват Partition
      const partitionElement = document.getElementById('partition');
      if (partitionElement) {
        const partitionCanvas = await html2canvas(partitionElement, {
          useCORS: true,
          scale: 2,
          logging: true,
          allowTaint: false,
        });
        const partitionDataURL = partitionCanvas.toDataURL('image/webp', 0.9);
        media.push(dataURLtoFile(partitionDataURL, 'partition.webp'));
      }

      // 3. Захват PartitionTop
      const partitionTopElement = document.getElementById('partition-top');
      if (partitionTopElement) {
        const partitionTopCanvas = await html2canvas(partitionTopElement, {
          useCORS: true,
          scale: 2,
          logging: true,
          allowTaint: false,
        });
        const partitionTopDataURL = partitionTopCanvas.toDataURL('image/webp', 0.9);
        media.push(dataURLtoFile(partitionTopDataURL, 'partition-top.webp'));
      }

      // Отправляем данные на сервер
      createCalc({ data: data, media: media, calc: calc, user: user, status: status, })
        .then((res) => {
          NotificationManager.success("Расчёт сохранен");
          if (status == 0) {
            const imageUrl = getImageURL({ type: "calc", path: res.file, size: "max" });

            // Открываем в новой вкладке
            const newWindow = window.open(imageUrl, '_blank', 'noopener,noreferrer');
          }
        })
        .catch((error) => {
          NotificationManager.error(error?.response?.data?.error ?? "Неизвестная ошибка при сохранении");
        });
    } catch (error) {
      NotificationManager.error("Ошибка при захвате изображений");
      console.error("Image capture error:", error);

    }
    finally {
      setLoadingFile(false)
    }
  }, [data, calc, user]);

  if (loading) {
    return <Loader full />;
  }
  return (

    <section className="box w-100">
      <Meta title="Конфигуратор" />

      <div className="config">
        <div className="left-column">
          <Row className="align-items-center mb-3">
            <Col md={4} >
              <p>Пользоваетель</p>
            </Col>
            <Col md={8}>
              <Button
                className="w-100 btn-primary"
                onClick={() => setShowUser({ show: true })}
              >
                {user?.lastName || user?.firstName || user?.patronymic
                  ? `${user.lastName} ${user.firstName} ${user.patronymic}`
                  : "Выбрать"}
              </Button>
            </Col>
          </Row>
          {data?.param && data?.param[6]?.data?.height && parseInt(data?.param[6]?.data?.height) <= 0 &&
            <div className="warning">
              <div className="title">
                <PiWarningCircleBold />
                <h2>Внимание!</h2>
              </div>
              {data?.param[6]?.data?.height == 0 ?
                <p>Высота монтажа скрытого трека выше УРОВНЯ ЧИСТОВОГО ПОТОЛКА на 36 мм</p>
                :
                <p>Высота полотна выше ПРОЕМА НА 10 мм</p>
              }
            </div>
          }
          <div className="main-img" ref={mainImgRef}>
            <img src={(data?.param && data?.param[2]?.largeMedia) ? getImageURL({ path: data?.param[2]?.largeMedia, type: "param", size: "max" }) : "/images/main.webp"} alt="" />
            {data?.param && data?.param[5]?.data?.doorCount && data?.param[5]?.data?.doorCount == 1 &&
              <div>
                <div className="left">
                  <img src={"/images/wallLeft.jpg"} alt="" />
                </div>
                <div className="right">
                  <img src={"/images/wallRight.jpg"} alt="" />
                </div>
              </div>}
            <PartitionMain
              maxWidth={maxWidthMain}
              maxHeight={maxWidthMain}
              plusProf={data?.param && data?.param[5]?.data?.plusProf}
              topBottomBarHeight={data?.param && (data?.param[6]?.data?.height ?? 0)}
              door={data?.param && data?.param[5]?.data?.door}
              partitionWidth={2600}
              partitionHeight={2600}
              numDoors={data?.param && data?.param[5]?.data?.doorCount}
              handlePositions={data.door} handleHeight={data?.param && data?.param[4]?.data?.heightHand}
              lineCount={data?.param && data?.param[5]?.data?.lineCount}
              colorProf={data?.param && data?.param[1]?.data?.color}
              colorLine={data?.param && data?.param[8]?.data?.color}
              fasad={data?.fasad}
              xMargin={xMargin}
              yMargin={yMargin}
              mirrorState={mirrorState}
            />
          </div>


          {catalog.items && catalog.items.length > 0 && catalog.items.filter(e => e.id < 5).map((item, i) =>
            <Row className="align-items-center mb-3">
              <Col md={4} >
                <p>{item.title}</p>
              </Col>
              <Col md={4} >
                <Button className={"w-100 btn-primary"} onClick={() =>
                  onShowParam({ item: item })
                }>{(data?.param && data?.param[item.id] && data?.param[item.id].title) ?? "Выбрать"}</Button>
              </Col>
              <Col md={4} className="mini">
                <img src={data.param && data.param[item.id] && getImageURL({ path: data.param[item.id]?.media, type: "param", size: "max" })} alt="" />
              </Col>
            </Row>

          )}
          {data?.param && data?.param[5]?.data?.doorCount && data?.param[4]?.data?.heightHand &&
            <>
              <p className="mb-3">Расположение ручек</p>
              {Array.from({ length: data?.param[5]?.data?.doorCount }, (_, index) => {
                const handleCheckChange = (e, field) => {
                  const updatedDoor = [...(data.door || [])];
                  updatedDoor[index] = { ...updatedDoor[index] };

                  // Устанавливаем значение выбранного поля
                  updatedDoor[index][field] = e.target.checked;


                  // Если выбираем "Слева" или "Справа", сбрасываем "Без ручек"
                  if (field === 'left' || field === 'right') {
                    updatedDoor[index].without = false;
                  }

                  // Если выбираем "Без ручек", сбрасываем "Слева" и "Справа"
                  if (field === 'without' && e.target.checked) {
                    updatedDoor[index].left = false;
                    updatedDoor[index].right = false;
                  }

                  setValue('door', updatedDoor);
                };

                return (
                  <Form key={index} className="d-flex justify-content-between mb-3">
                    <p>Дверь №{index + 1}</p>
                    <Form.Check
                      label="Слева"
                      type="checkbox"
                      checked={data.door && data.door[index] && data.door[index].left}
                      onChange={(e) => handleCheckChange(e, 'left')}
                    />
                    <Form.Check
                      label="Справа"
                      type="checkbox"
                      checked={data.door && data.door[index] && data.door[index].right}
                      onChange={(e) => handleCheckChange(e, 'right')}
                    />
                    <Form.Check
                      label="Без ручек"
                      type="checkbox"
                      checked={data.door && data.door[index] && data.door[index].without}
                      onChange={(e) => handleCheckChange(e, 'without')}
                    />
                  </Form>
                );
              })}


            </>
          }
          {data?.param && data?.param[5]?.data?.doorCount &&
            <>
              <p className="mb-3">Комплекты роликов: количество(комплектов) {data?.param[5]?.data?.doorCount}</p>
              {Array.from({ length: data?.param[5]?.data?.doorCount }, (_, index) => (
                <Row key={index} className="d-flex juxtify-content-between mb-3">
                  <Col md={6}> <p>Дверь №{index + 1}</p></Col>
                  <Col md={6}>
                    <Select
                      value={calc?.doorWidth <= 640 ? catalog.items.find(e => e.id == 9).params[0].id : data.rol && data.rol[index]?.id}
                      title="Выбрать"
                      // label={catalog.items.find(e => e.id == 9).title}
                      onClick={(e) => setValue(`rol.${index}`, e.data)}
                      data={catalog.items.find(e => e.id == 9)?.params.map((e) => ({ value: e.id, data: e, title: e.title }))}
                    />
                  </Col>
                </ Row>
              ))}
            </>
          }
          {calc.doorWidth <= 640 && calc.totalHandles &&
            <div className="warning mt-3">
              <div className="title">
                <PiWarningCircleBold />
                <h2>Внимание!</h2>
              </div>
              <p>Мягкое закрывание можно поставить только на ширину двери от 640мм!!!</p>
            </div>
          }
          {catalog.items && catalog.items.length > 0 && catalog.items.filter(e => e.id == 8).map((item, i) =>

            <Row className="align-items-center mb-3">
              <Col md={6} >
                <p>{item.title}</p>
              </Col>
              <Col md={6}>
                <Select
                  value={data.param && data.param[item.id]?.id}
                  title="Выбрать"
                  // label={item.title}
                  onClick={(e) => {
                    onParam(e.data)
                  }}
                  data={item.params.map((e) => ({ value: e.id, data: e, title: e.title }))}
                />
              </Col>
            </Row>
          )}

        </div>
        <div className="right-column">
          <div className="w-100">
            <Row className="align-items-center mb-3">
              {catalog.items && catalog.items.length > 0 && catalog.items.filter(e => e.id == 6).map((item, i) =>

                <Col md={4}>

                  <Button className={"btn-primary"} onClick={() =>
                    onShowParam({ item: item })
                  }>{(data?.param && data?.param[item.id] && data?.param[item.id].title) ?? item.title}</Button>
                </Col>
              )}
              {catalog.items && catalog.items.length > 0 && catalog.items.filter(e => e.id == 5).map((item, i) =>
                <Col md={4}>

                  <Button onClick={() =>
                    onShow(item)
                  }>{(data?.param && data?.param[item.id] && "Схема " + data?.param[item.id].title) ?? item.title}</Button>
                </Col>
              )}
              <Col md={4}>
                <Button >
                  Количество вставок
                </Button>
              </Col>
            </Row>
            <div className="size">
              <p className="mt-3 me-3">Размеры проема</p>
              <div className="d-flex mt-3">
                <Input
                  label="Ширина"
                  value={data.width}
                  onChange={(e) => setValue("width", e)}
                  placeholder="0"
                  type="number"
                  numberPanel={true}
                  error={calc?.limitWidth}
                />

                <Input
                  type="number"
                  className="ms-3"
                  label="Высота"
                  value={data.height}
                  onChange={(e) => setValue("height", e)}
                  placeholder="0"
                  error={calc?.limitHeight}
                  numberPanel={true}
                />
              </div>
            </div>
            {(calc.limitHeight || calc.limitWidth) &&
              <div className="warning mt-3">
                <div className="title">
                  <PiWarningCircleBold />
                  <h2>Внимание!</h2>
                </div>
                {calc.limitHeight && <p>Высота полотна не может быть меньше 1900 и больше 3200</p>}
                {calc.limitWidth && <p>Ширина полотна не может быть меньше 450 и больше 1600</p>}
              </div>
            }
            <div className="mt-3" id="partition" >
              {/* <img src="/images/glass.webp" alt="" /> */}
              <Partition

                maxWidth={maxWidth}
                maxHeight={maxHeight}
                title={data?.param && data?.param[2]?.title}
                plusProf={data?.param && data?.param[5]?.data?.plusProf}
                topBottomBarHeight={data?.param && (data?.param[6]?.data?.height ?? 0)}
                partitionWidth={calc.width}
                partitionHeight={calc.height}
                numDoors={data?.param && data?.param[5]?.data?.doorCount}
                handlePositions={data.door}
                handleHeight={data?.param && data?.param[4]?.data?.heightHand}
                onChange={(e) => onShowParam({ item: catalog.items.find(e => e.id == 2), index: e })}
                fasad={data?.fasad ?? false}
                totalHandles={calc?.totalHandles}
              />

            </div>
            <div className="size">
              <p className="mt-3 me-3">Размеры полотен</p>
              <div className="d-flex mt-3">
                <Input
                  label="Ширина"
                  name="width"
                  value={calc.doorWidth}
                  // register={register}
                  placeholder="0"
                  type="number" // Устанавливаем тип поля как число
                />

                <Input
                  className="ms-3"
                  label="Высота"
                  value={calc.doorHeight}
                  name="height"
                  // register={register}
                  placeholder="0"
                />
              </div>
            </div>

            <div className="mt-3 mb-4 " id="partition-top">
              {/* <img src="/images/glass.webp" alt="" /> */}
              <PartitionTop

                maxWidth={maxWidth}
                maxHeight={200}
                partitionWidth={calc.width}
                partitionHeight={calc.height}
                numDoors={data?.param && data?.param[5]?.data?.doorCount}
                wallSize={data?.param && data?.param[5]?.data?.wallSize}
                wallType={data?.param && data?.param[5]?.data?.wallType}
                door={data?.param && data?.param[5]?.data?.door}
                plusProf={data?.param && data?.param[5]?.data?.plusProf}
                lineCount={data?.param && data?.param[5]?.data?.lineCount}
                mirrorState={mirrorState}
                lineWidth={calc.lineWidth}
              />

            </div>
            <Row className="align-items-center mb-3">
              <Col mb={4}>
                <Button
                  disabled={!data.param || !data.param[5] || !data.param[5].file1}
                  onClick={() => window.open(getImageURL({ path: data.param[5]?.file1, type: "param", size: "max" }), '_blank', 'noopener,noreferrer')}
                >
                  Схема крепления
                </Button>
              </Col>
              <Col mb={4}>
                <Button
                  disabled={!data.param || !data.param[5] || !data.param[5].file2}
                  onClick={() => window.open(getImageURL({ path: data.param[5]?.file2, type: "param", size: "max" }), '_blank', 'noopener,noreferrer')}
                >
                  Схема монтажа
                </Button>
              </Col>
              <Col mb={4}>
                <Button onClick={handleMirrorToggle}>
                  Развернуть
                </Button>
              </Col>



            </Row>
          </div >
        </div>
      </div>
      <Col md={5}>
        <p className="mt-3">Метод рассчета(требуется уточнение)</p>
        <p >Высота двери = <b>{(calc?.doorHeight ?? 0) / 1000} м</b></p>
        <p>Ширина двери = <b>{(calc.doorWidth ?? 0) / 1000} м</b></p>
        {calc?.door && calc?.door.length > 0 &&
          <>
            <br />
            <p> Наценка = <b>{(calc.xPrice ?? 0)}%</b></p>
            {calc.door.map((item, index) =>
              <p>Цена двери {index + 1} = <b>{customPrice(item)}</b></p>
            )}
            <br />
          </>
        }

        <p>Цена профиля = <b>{customPrice(calc.priceProf)}</b></p>
        <p>Цена фасада(стекло) = <b>{customPrice(calc.priceFasad)}</b></p>
        <p>Цена направляющих = <b>{customPrice(calc.priceLine)}</b></p>
        <p>Цена за ролики = <b>{customPrice(calc.priceRol)}</b></p>
        <p>Цена за декор = ? = <b>{customPrice(calc.priceDekor)}</b></p>
        <p>Цена ручек = <b>{customPrice(calc.priceHand)}</b></p>
        <h3 className="mt-3">Стоимость = {customPrice(calc.total)}</h3>
      </Col>
      <Row className="mt-3">
        <Button className="me-3" onClick={() => onSave(1)} disabled={loadingFile}>
          {loadingFile ?
            <Loader color={"black"} />
            : "Заказать"
          }
        </Button>
        <Button onClick={() => onSave(0)} disabled={loadingFile}>
          {loadingFile ?
            <Loader color={"white"} />
            : "Распечатать"
          }
        </Button>
      </Row>
      <CustomModal
        title={"Пользователи"} // Используйте опциональный цепочечный оператор
        show={showUser.show}
        dialogClassName={"modal-xl "}
        setShow={(e) => setShowUser({ show: e, data: e })}
        footer={
          <>
            <Button className="btn-light me-3" onClick={() => setShowUser({ show: false, data: null })}>
              Отмена
            </Button>
            <Button
              disabled={!showUser.data} // Отключить кнопку, если ничего не выбрано
              onClick={() => {
                setUser(showUser.data)
                setShowUser({ show: false, data: null })
              }}
            >
              Сохранить
            </Button>
          </>
        }
      >
        {showUser?.new ?
          <UserCreate setShowUser={setShowUser} config={true} />
          :
          <Users setShowUser={setShowUser} showUser={showUser} config={true} />
        }
      </CustomModal>

      <CustomModal
        title={show.data?.title} // Используйте опциональный цепочечный оператор
        show={show.show}
        dialogClassName={"modal-xl "}
        setShow={(e) => setShow({ show: e, data: e })}
        footer={
          <>
            <Button className="btn-light me-3" onClick={() => setShow({ show: false, data: null })}>
              Отмена
            </Button>
            <Button
              disabled={selectedItemId === null} // Отключить кнопку, если ничего не выбрано
              onClick={() => {
                const doorCount = show.data?.params[selectedItemId]?.data?.doorCount || 0;
                const doorObject = catalog.items.find(e => e.id == 9).params;
                const hand = show.data?.params[selectedItemId]?.data?.doorHand ?? null
                // Создаем массив объектов doorObject в количестве doorCount
                const rol = Array.from({ length: doorCount }, (_, i) => {
                  // Проверяем, существует ли hand и hand[i], и есть ли left или right
                  if (hand && hand[i] && (hand[i].left || hand[i].right)) {
                    return doorObject[1]; // Используем doorObject[1], если условие выполнено
                  } else {
                    return doorObject[0]; // Иначе используем doorObject[0]
                  }
                });
                reset({
                  ...data,
                  door: hand,
                  fasad: null,
                  rol: rol,
                  width: show.data?.params[selectedItemId]?.data?.dafaultWidth,
                  height: show.data?.params[selectedItemId]?.data?.defaultHeight,
                  param: {
                    ...data.param,
                    [1]: data.param[1] ?? catalog?.items?.find(e => e.id == 1)?.params?.find(e => e.id == 61),
                    [2]: data.param[2] ?? catalog?.items?.find(e => e.id == 2)?.params?.find(e => e.id == 54),
                    [4]: data.param[4] ?? catalog.items.find(e => e.id == 4).params[0],
                    [8]: data.param[8] ?? catalog?.items?.find(e => e.id == 8)?.params?.find(e => e.id == 99),
                    [show.data?.id]: show.data?.params[selectedItemId],
                  }
                });
                setShow({ show: false, data: null });
                setSelectedItemId(null); // Сбросить выбор после сохранения
              }}
            >
              Сохранить
            </Button>
          </>
        }
      >
        <div className="d-flex w-100">
          <div className="d-flex row row-cols-1 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-3 row-cols-xl-3 gx-4 gy-4">
            {show?.data?.params && show?.data?.params.map((item, index) => ( // Используйте map с индексом
              <Card
                key={item.id} // Убедитесь, что у каждого элемента есть уникальный ключ
                className={`card-selector ${selectedItemId === index ? 'selected' : ''}`} // Добавлено условное имя класса
                onClick={() => setSelectedItemId(index)} // Установите selectedItemId при клике
              >
                <div className="mini">
                  <img src={getImageURL({ path: item?.media, type: "param", size: "max" })} alt="" />
                </div>
                <p>{item.title}</p>
              </Card>
            ))}
          </div>
          <div className="middle-img ">

            <img src={getImageURL({ path: show.data?.params && (show.data?.params[selectedItemId]?.largeMedia ?? show.data?.params[selectedItemId]?.media), type: "param", size: "max" })} alt="" />

          </div>

        </div>

      </CustomModal>

      <CustomModal
        title={showParam.data?.title} // Используйте опциональный цепочечный оператор
        show={showParam.show}
        dialogClassName={"modal-xl "}
        setShow={(e) => setShowParam({ show: e, data: e })}
        footer={
          <>
            <Button className="btn-light me-3" onClick={() => setShowParam({ show: false, data: null })}>
              Отмена
            </Button>
            <Button
              disabled={selectedItemId === null} // Отключить кнопку, если ничего не выбрано
              onClick={() => {
                if (showParam?.data?.id == 2) {
                  if (showParam?.index) {
                    reset({
                      ...data,
                      fasad: {
                        ...data?.fasad,
                        [showParam?.index]: showParam.data?.params[selectedItemId],
                      }
                    });
                  }
                  else {
                    reset({
                      ...data,
                      fasad: null,
                      param: {
                        ...data.param,
                        [showParam.data?.id]: showParam.data?.params[selectedItemId],
                      }
                    });
                  }
                }
                else if (showParam?.data?.id == 6) {
                  reset({
                    ...data,
                    param: {
                      ...data.param,
                      [showParam.data?.id]: showParam.data?.params[selectedItemId],
                      [5]: null,
                    }
                  });
                }
                else {
                  reset({
                    ...data,
                    param: {
                      ...data.param,
                      [showParam.data?.id]: showParam.data?.params[selectedItemId],
                    }
                  });
                }
                setShowParam({ show: false, data: null });
                setSelectedItemId(0); // Сбросить выбор после сохранения
              }}
            >
              Сохранить
            </Button>
          </>
        }
      >{showParam?.data?.id == 2 ?
        <div className="config-modal">
          <ModalCategories showParam={showParam} item={selectedItemId} setItem={setSelectedItemId} />
        </div>
        :
        <div className="d-flex w-100 row row-cols-2 row-cols-lg-1 gx-1 gy-1 gx-lg-4 gy-lg-4">
          {showParam?.data?.params && showParam?.data?.params.map((item, index) => ( // Используйте map с индексом
            <Card
              key={item.id} // Убедитесь, что у каждого элемента есть уникальный ключ
              className={`card-selector-param ${selectedItemId === index ? 'selected' : ''}`} // Добавлено условное имя класса
              onClick={() => setSelectedItemId(index)} // Установите selectedItemId при клике
            >
              <div className="mini">
                <img
                  src={getImageURL({ path: item?.media, type: "param", size: "max" })}
                  alt=""
                  loading="eager"
                  decoding="async"
                />
              </div>
              <p>{item.title}</p>
            </Card>
          ))}
        </div>
        }
      </CustomModal>
    </section >
  );
};

export default EditCategory;
