import React, { useState } from 'react';
import { getImageURL } from '../helpers/image';

function DoorMain({ x, y, width, height, handlePosition, handleHeight, grayBorderSize, colorProf, oneDoor, i, fasad, xMargin, yMargin, handleSize }) {
  const borderWidth = 1; // Ширина черной границы

  const innerWidth = width - 2 * grayBorderSize - 2 * borderWidth;
  const innerHeight = height - 2 * grayBorderSize - 2 * borderWidth;
  const [hovered, setHovered] = useState(false);
  const [selected, setSelected] = useState(false);


  const imagePathLeft = "/images/wallLeft.jpg";
  const imagePathRight = "/images/wallRight.jpg";

  const handleMouseOver = () => setHovered(true);
  const handleMouseOut = () => setHovered(false);
  const handleSelect = () => setSelected(!selected);

  const fillColor = selected ? 'red' : (hovered ? 'pink' : 'white');

  const handleY = height - handleSize - handleHeight / 2;
  const handles = (() => {
    if (handlePosition?.left && handlePosition?.right) {

      return [

        <rect
          key="left"
          x={grayBorderSize + borderWidth}
          y={handleY}
          width={grayBorderSize}
          height={handleHeight}
          stroke={"black"}
          strokeWidth={borderWidth}
          fill={colorProf ?? "lightgray"}
        />,
        <rect
          key="right"
          x={width - 2 * grayBorderSize - borderWidth}
          y={handleY}
          width={grayBorderSize}
          height={handleHeight}
          stroke={"black"}
          strokeWidth={borderWidth}
          fill={colorProf ?? "lightgray"}
        />,
      ];
    } else if (handlePosition?.left) {
      return [
        <rect
          key="left"
          x={grayBorderSize + borderWidth}
          y={handleY}
          width={grayBorderSize}
          height={handleHeight}
          stroke={"black"}
          strokeWidth={borderWidth}
          fill={colorProf ?? "lightgray"}
        />,
      ];
    } else if (handlePosition?.right) {
      return [
        <rect
          key="right"
          x={width - 2 * grayBorderSize - borderWidth}
          y={handleY}
          width={grayBorderSize}
          height={handleHeight}
          stroke={"black"}
          strokeWidth={borderWidth}
          fill={colorProf ?? "lightgray"}
        />,
      ];
    } else {
      return []; // Или верните null, если handlePosition некорректен
    }
  })();


  return (
    <g transform={`translate(${x}, ${y})`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={handleSelect}>
      <defs>
        <pattern id="glass-pattern" patternUnits="userSpaceOnUse" width="10" height="10">
          <line x1="0" y1="0" x2="10" y2="10" stroke="lightgray" fill="white" strokeWidth="2" strokeDasharray="2 2" />
          <line x1="0" y1="10" x2="10" y2="0" stroke="lightgray" strokeWidth="2" fill="white" strokeDasharray="2 2" />
        </pattern>
      </defs>

      {/* Серый профиль (полностью залитый) */}
      {(oneDoor && (i == 1) || !oneDoor) &&
        <>
          <defs>
            <clipPath id="doorClipPath">
              <rect x={0} y={0} width={width} height={height} />
            </clipPath>
          </defs>
          <g clipPath="url(#doorClipPath)" >
            <foreignObject x={-x - xMargin} y={-y - 2.22 * yMargin} width="640" height="480">
              <img
                src={getImageURL({ path: fasad?.largeMedia, type: "param", size: "max" })}
                className="center"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </foreignObject>
          </g>
          {handlePosition && !handlePosition.without && handles}
          <style>
            {`
      .center {
          width: 640px;
          border-radius: $radius;
          margin-bottom: 3em;
          height: auto;
          object-fit: contain;
          

          @media (max-width: 1639px) {
            width: 615px;
          }

          @media (max-width: 1530px) {
            width: 580px;
          }

          @media (max-width: 1440px) {
            width: 500px;
          }

          @media (max-width: 1300px) {
            width: 400px;
          }

          @media (max-width: 1100px) {
            width: 350px;
          }
        }
    `}
          </style>
          <rect
            x={grayBorderSize + borderWidth}
            y={borderWidth}
            width={width - 2 * grayBorderSize - borderWidth}
            height={grayBorderSize}
            stroke={colorProf ?? "lightgray"}
            // style={{ filter: 'opacity(0.7)' }}
            fill={colorProf ?? "lightgray"}
          />
          <rect
            x={grayBorderSize + borderWidth}
            y={height - grayBorderSize}
            width={width - 2 * grayBorderSize - borderWidth}
            height={grayBorderSize}
            // style={{ filter: 'opacity(0.7)' }}
            stroke={colorProf ?? "lightgray"}
            fill={colorProf ?? "lightgray"}
          />
          <rect
            x={borderWidth}
            y={borderWidth}
            width={grayBorderSize}
            height={height - borderWidth}
            stroke={colorProf ?? "lightgray"}
            fill={colorProf ?? "lightgray"}
          // style={{ filter: 'opacity(0.7)' }}
          />
          <rect
            x={width - grayBorderSize}
            y={borderWidth}
            width={grayBorderSize}
            height={height - borderWidth}
            stroke={colorProf ?? "lightgray"}
            // style={{ filter: 'opacity(0.7)' }}
            fill={colorProf ?? "lightgray"}
          />
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            stroke="black"
            strokeWidth={borderWidth}
            fill="transparent"
          />
          <rect
            x={grayBorderSize + borderWidth}
            y={grayBorderSize + borderWidth}
            width={width - 2 * grayBorderSize - 2 * borderWidth}
            height={height - 2 * grayBorderSize - 2 * borderWidth}
            stroke="black"
            strokeWidth={borderWidth}
            fill="transparent"
          />
        </>
      }



    </g >
  );
}

export default DoorMain;
