import React, { useCallback, useState } from "react";
import { Card, Col, Row, Container, Form } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import { roles } from "../../helpers/member";
import { createMember } from "../../services/member";
import { titles } from "../../helpers/titles";
import { createUser } from "../../services/user";
import { useNavigate } from "react-router-dom";

const UserCreate = ({ config, setShowUser }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    createUser(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      )
      .finally(() => {
        setBtnLoading(false)
        if (config) {
          setShowUser((prev) => ({ ...prev, new: false }))
        }
        else {
          navigate(-1)
        }

      })
  }, []);


  return (
    <Container fluid={true}>
      <Meta title="Добавление пользователя" />
      <section className="box">
        <h3 className="mb-4">Добавление пользователя</h3>
        <Card body>
          <Row>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Фамилия"
                  name="lastName"
                  placeholder="Введите фамилию (Необязательно)"
                  errors={errors}
                  register={register}
                  validation={{
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Имя"
                  name="firstName"
                  errors={errors}
                  register={register}
                  placeholder="Введите имя"
                  validation={{
                    required: "Введите имя",
                    maxLength: {
                      value: 20,
                      message: "Максимально 20 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Отчество"
                  name="patronymic"
                  errors={errors}
                  register={register}
                  placeholder="Введите отчество (Необязательно)"
                  validation={{
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Номер телефона"
                  name="phone"
                  placeholder="+7(000)000-00-00"
                  mask="+7(999)999-99-99"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Адрес"
                  name="about"
                  placeholder="Введите адрес"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <Select
                className="mb-4"
                label="Пол"
                onClick={(e) => setValue("sex", e.value)}
                value={form.sex ?? ""}
                data={[
                  { title: "Не указано", value: "" },
                  { title: "Мужской", value: "man" },
                  { title: "Женский", value: "woman" },
                ]}
              />
            </Col>
          </Row>


          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-primary"
              isValid={isValid}
              isLoading={btnLoading}
              disabled={btnLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить изменения
            </Button>
          </div>
        </Card>
      </section>
    </Container>
  );
};

export default UserCreate;
