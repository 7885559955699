import React from 'react';
import useIsMobile from '../hooks/useIsMobile';
import Door from './Door';
import SizeLineVertical from './SizeLineVertical';

function Partition({ partitionWidth, partitionHeight, numDoors, handlePositions, handleHeight, topBottomBarHeight, plusProf, maxWidth, maxHeight, title, onChange, fasad, totalHandles }) {
  const isMobileSM = useIsMobile("1300px");
  const isMobileXS = useIsMobile("1100px");
  const fontSize = isMobileSM ? isMobileXS ? 10 : 12 : 14
  const handleSize = 1000

  // Вычисление масштабного коэффициента
  const sideRectWidth = partitionWidth / numDoors;
  const grayBorderSize = 30

  // Вычисление масштабного коэффициента с учетом боковых прямоугольников
  const maxScaleX = maxWidth / (parseInt(partitionWidth) + 2 * sideRectWidth);
  const maxScaleY = maxHeight / (partitionHeight);
  const maxScale = Math.min(maxScaleX, maxScaleY);

  const doorWidth = partitionWidth / numDoors;
  const doorHeight = partitionHeight - topBottomBarHeight - 20;

  const scaledHandleSize = handleSize * maxScale;
  const scaledDoorWidth = doorWidth * maxScale;
  const scaledDoorHeight = doorHeight * maxScale;
  const scaledHandleHeight = handleHeight * maxScale;
  const scaledTen = 10 * maxScale
  const scaledHandleWidth = 5 * 10 * maxScale;
  const scaledTopBottomBarHeight = 2 * topBottomBarHeight * maxScale
  const scaledGrayBorderSize = grayBorderSize * maxScale

  const totalDoorsWidth = numDoors * scaledDoorWidth;
  const xOffset = (maxWidth - totalDoorsWidth + 2 / 3 * scaledDoorWidth) / 2; // Центрирование дверей




  const doors = Array.from({ length: numDoors }, (_, i) => (
    <Door
      key={i}
      x={xOffset + i * (scaledDoorWidth)}
      y={scaledTopBottomBarHeight}
      width={scaledDoorWidth}
      height={scaledDoorHeight}
      handlePosition={handlePositions && handlePositions[i]}
      handleHeight={scaledHandleHeight}
      handleWidth={scaledHandleWidth}
      grayBorderSize={scaledGrayBorderSize}
      handleSize={scaledHandleSize}
      title={title}
      fasad={fasad && fasad[i + 1]}
      onChange={() => onChange(i + 1)}
    />

  ));

  if (doors.length > 0) {
    return (
      <div className="container">
        <svg width={maxWidth} height={scaledDoorHeight + (scaledTopBottomBarHeight > 0 ? scaledTopBottomBarHeight : -scaledTopBottomBarHeight) + 40} viewBox={`0 0 ${maxWidth} ${scaledDoorHeight + scaledTopBottomBarHeight + 40}`}>
          <defs>
            <pattern id="lightHatch" patternUnits="userSpaceOnUse" width="20" height="20">
              <line x1="0" y1="0" x2="10" y2="10" stroke="lightgray" strokeWidth="1" />
            </pattern>
          </defs>
          <rect x={0} y={0} width={maxWidth} height={scaledDoorHeight + (scaledTopBottomBarHeight > 0 ? scaledTopBottomBarHeight : -scaledTopBottomBarHeight)} fill="url(#lightHatch)" />
          {/* Верхняя полоса */}
          <rect x={xOffset} y="0" width={totalDoorsWidth} height={scaledTopBottomBarHeight} fill="gray" stroke='black' />


          {doors}
          <SizeLineVertical
            x={xOffset - scaledDoorWidth / 2}
            y={scaledTopBottomBarHeight + scaledTen}
            height={scaledDoorHeight - 2 * scaledTen}
            width={scaledDoorWidth / 2}
            text={doorHeight}
          />
          {totalHandles &&
            <SizeLineVertical
              x={xOffset - scaledDoorWidth / 4}
              y={scaledTopBottomBarHeight + scaledDoorHeight - scaledHandleSize}
              height={scaledHandleSize}
              width={scaledDoorWidth / 4}
              text={handleSize}
            />
          }
          {topBottomBarHeight &&
            <>
              <SizeLineVertical
                x={xOffset - scaledDoorWidth}
                y={0}
                height={scaledDoorHeight + scaledTopBottomBarHeight}
                width={scaledDoorWidth}
                text={partitionHeight}
              />
              <SizeLineVertical
                x={xOffset - scaledDoorWidth / 4}
                y={0}
                height={scaledTopBottomBarHeight}
                width={scaledDoorWidth / 4}
                text={topBottomBarHeight}
                textBelowLine={true}
              />
            </>
          }
          {/* Нижняя полоса */}
          {/* <rect x={xOffset} y={scaledTopBottomBarHeight + scaledDoorHeight} width={totalDoorsWidth} height={scaledTopBottomBarHeight} fill="gray" stroke='black' /> */}

        </svg>
      </div>
    );
  }
}

export default Partition;
