import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getCalcs = async (data) => {
  const response = await $authApi.get(apiRoutes.CALCS, {
    params: data,
  });
  return response?.data;
};
const getCalc = async (id) => {
  const response = await $authApi.get(apiRoutes.CALC, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editCalc = async (data) => {
  const response = await $authApi.put(apiRoutes.CALCS, data);
  return response?.data;
};
const deleteCalc = async (id) => {
  const response = await $authApi.delete(apiRoutes.CALCS, {
    data: { id },
  });
  return response?.data;
};

const createCalc = async ({ data, calc, user, media, status }) => {
  const formData = new FormData();
  // if (media) {
  //   // Проверяем, является ли data.file массивом
  //   if (Array.isArray(media)) {
  //     for (let i = 0; i < media.length; i++) {
  //       const file = media[i];
  //       formData.append(
  //         'file', // Используем имя, которое ожидает Multer
  //         file,
  //         file.name || `door_image_${Date.now()}.webp`
  //       ); // Используем динамический ключ
  //     }
  //   }
  //   // Проверяем, является ли data.file объектом
  //   else if (typeof data.file === 'object') {
  //     // Проходим по ключам объекта
  //     Object.keys(data.file).forEach(key => {
  //       const file = data.file[key];
  //       if (file instanceof File) {
  //         formData.append('file', file, Name);
  //       } else {
  //         console.error(`Элемент с ключом ${key} не является файлом.`);
  //       }
  //     });
  //   }
  //   // Если это не массив и не объект с файлами
  //   else {
  //     console.error('Файл не найден или имеет неверный формат.');
  //   }
  // }
  // Добавляем данные в FormData
  if (media) {
    const files = Array.isArray(media)
      ? media
      : Object.values(media);

    files.forEach((file, index) => {
      if (file instanceof File) {
        formData.append(
          'file', // Используем имя, которое ожидает Multer
          file,
          file.name || `door_image_${Date.now()}.webp`
        );
      } else {
        console.error('Invalid file format:', file);
      }
    });
  }

  formData.append("dataParse", JSON.stringify(data));
  formData.append("calc", JSON.stringify(calc));
  formData.append("user", JSON.stringify(user));
  formData.append("status", status);


  try {
    const response = await $authApi.post(apiRoutes.CALCS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при отправке данных:', error);
    throw error;
  }
};
export { getCalcs, getCalc, editCalc, deleteCalc, createCalc };