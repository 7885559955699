import React from 'react';
import DoorTop from './DoorTop';
import Line from './Line';
import Arrow from './Arrow';
import SizeLineHorizontal from './SizeLineHorizontal';

function PartitionTop({ partitionWidth, numDoors, wallSize, wallType, plusProf, door, lineCount, maxWidth, maxHeight, mirrorState, lineWidth }) {

  const doorHeight = 10; // Высота двери и боковых прямоугольников
  const topBottomBarHeight = 50; // Высота верхней и нижней полос (если нужны)
  // Ширина боковых прямоугольников (равна ширине двери)
  const sideRectWidth = partitionWidth / numDoors;

  const doorWidth = sideRectWidth + parseInt(plusProf)
  // Вычисление масштабного коэффициента с учетом боковых прямоугольников
  const maxScaleX = maxWidth / (parseInt(partitionWidth) + 2.5 * sideRectWidth); // Учли ширину боковых прямоугольников
  const maxScaleY = maxHeight / (topBottomBarHeight); // Учли высоту верхней/нижней полос и дверей
  const maxScale = Math.min(maxScaleX, maxScaleY);
  function minusArray(arr, min = 1, max = 4) {
    if (!arr || arr.length === 0) {
      return arr;
    }

    const positions = arr.map(item => parseInt(item.position));
    const minPosition = Math.max(...positions); // Находим минимальное значение position
    let offset = 0;

    if (minPosition > 0) {
      offset = -minPosition - 1; // Вычисляем смещение, если минимальное position меньше 0
    }

    const hasZero = positions.includes(0);

    return arr.map(item => ({
      ...item,
      position: parseInt(item.position) + offset - (hasZero ? 1 : 0) // Применяем смещение и ограничиваем сверху значением max
    }));
  }
  function centerArray(arr, min = 1, max = 4) {
    if (!arr || arr.length === 0) {
      return arr;
    }

    const positions = arr.map(item => parseInt(item.position));
    const minPosition = Math.max(...positions); // Находим минимальное значение position
    let offset = 0;

    if (minPosition < -1) {
      offset = -minPosition - 1;
    }

    const hasZero = positions.includes(0);

    return arr.map(item => ({
      ...item,
      position: parseInt(item.position) + offset - (hasZero ? 1 : 0) // Применяем смещение и ограничиваем сверху значением max
    }));
  }

  if (door) {
    door = minusArray(door)
    door = centerArray(door)
    if (mirrorState % 2 != 0) {
      wallSize = [wallSize[1], wallSize[0]]
      wallType = [wallType[1], wallType[0]]
    }
    if (mirrorState == 1) {
      if (Array.isArray(door)) {
        door.reverse();
      }
      // if (linePositions) {
      //   linePositions.reverse();
      // }
    }
    if (mirrorState == 2) {
      door = door.map(item => ({
        ...item,
        position: -parseInt(item.position)// Применяем смещение и ограничиваем сверху значением max
      }));
      door = minusArray(door)
      door = centerArray(door)
    }
    if (mirrorState == 3) {
      door = door.map(item => ({
        ...item,
        position: -parseInt(item.position)// Применяем смещение и ограничиваем сверху значением max
      }));
      door = minusArray(door)
      door = centerArray(door)
      if (Array.isArray(door)) {
        door.reverse();
      }
    }
  }

  const positions = door?.map(door => door.position);
  const linePositions = Array.from(new Set(positions));

  const lineWidthCount = Array.from(new Set(lineWidth));
  const one = lineWidthCount.length > 1 ? false : true

  if (mirrorState % 2 != 0 && (numDoors != 4 || lineCount != 2)) {

    if (linePositions) {
      linePositions.reverse();
    }
  }



  const scaledDoorWidth = sideRectWidth * maxScale;
  const scaledSideRectWidth = scaledDoorWidth; // Ширина боковых прямоугольников = ширине двери

  const scaledPlusProf = topBottomBarHeight * maxScale;

  const totalDoorsWidth = numDoors * scaledDoorWidth;
  const doorWidthPlus = scaledDoorWidth + doorHeight;

  const scaledLineWidth = lineWidth?.map(item => item * maxScale);

  let maxVal = Number.MIN_SAFE_INTEGER; // начальная минимальная валидная величина
  let minVal = Number.MAX_SAFE_INTEGER; // начальная максимальная валидная величина

  for (let i = 0; i < numDoors; i++) {
    const currentVal = door && door[i].position * doorHeight;

    if (currentVal > maxVal) {
      maxVal = currentVal; // обновляем максимальное значение
    }

    if (currentVal < minVal) {
      minVal = currentVal; // обновляем минимальное значение
    }
  }
  const totalDoorsHeight = (maxVal - minVal) + doorHeight;
  const yOffset = (maxHeight - totalDoorsHeight - 2 * doorHeight) / 2 - 30;

  const grayStripeWidth = doorHeight / 2

  let wallHeight1
  let wallHeight2
  let wallWidth1
  let wallWidth2
  let wallY1
  let wallY2
  let val = [0, 0, 0, 0]
  let xval = [0, 0, 0, 0]
  let xline = [0, 0, 0, 0]

  if (wallType && wallSize) {
    wallWidth1 = scaledSideRectWidth
    wallWidth2 = scaledSideRectWidth

    if (wallType[0] == "vertical") {
      if (wallSize[0] == "max") {
        wallHeight1 = totalDoorsHeight + 2 * doorHeight
        wallY1 = yOffset
      }
      else {
        if (mirrorState > 1) {
          wallHeight1 = - door[0]?.position * doorHeight
          wallY1 = yOffset - door[0]?.position * doorHeight - wallHeight1
        }
        else {
          wallY1 = yOffset - door[0]?.position * doorHeight + doorHeight
          wallHeight1 = totalDoorsHeight + doorHeight + door[0]?.position * doorHeight
        }

      }
    }
    else {
      wallHeight1 = doorHeight
      if (wallSize[0] == "max") {
        wallY1 = yOffset - door[0]?.position * doorHeight
      }
      else {
        if (mirrorState > 1) {
          wallY1 = yOffset - door[0]?.position * doorHeight - doorHeight

        }
        else {
          wallY1 = yOffset - door[0]?.position * doorHeight + doorHeight
        }

      }
    }
    if (wallType[1] == "vertical") {
      if (wallSize[1] == "max") {
        wallHeight2 = totalDoorsHeight + 2 * doorHeight
        wallY2 = yOffset
      }
      else {

        if (mirrorState > 1) {
          wallHeight2 = - door[parseInt(numDoors) - 1]?.position * doorHeight
          wallY2 = yOffset - door[parseInt(numDoors) - 1]?.position * doorHeight - wallHeight2

        }
        else {
          wallY2 = yOffset - door[parseInt(numDoors) - 1]?.position * doorHeight + doorHeight
          wallHeight2 = totalDoorsHeight + door[parseInt(numDoors) - 1]?.position * doorHeight + doorHeight
        }
      }
    }
    else {
      wallHeight2 = doorHeight
      if (wallSize[1] == "max") {
        wallY2 = yOffset - door[parseInt(numDoors) - 1]?.position * doorHeight
      }
      else {
        if (mirrorState > 1) {
          wallY2 = yOffset + door[parseInt(numDoors) - 1]?.position * doorHeight + doorHeight

        }
        else {
          wallY2 = yOffset - door[parseInt(numDoors) - 1]?.position * doorHeight + doorHeight
        }
      }

    }
    if (wallSize[0] == "eq") {
      if (wallSize[1] == "eq") {
        if (lineCount == 1) {
          xline[0] = ((wallWidth1 + totalDoorsWidth + wallWidth2 - scaledLineWidth[0]) / 2)

          if (numDoors == 1) {
            xval[0] = grayStripeWidth

          }
          if (numDoors == 2) {
            xval[0] = grayStripeWidth
            val[0] = -grayStripeWidth
            xval[1] = 0
            val[1] = -grayStripeWidth
          }

        }
        if (lineCount == 2) {

          if (numDoors == 2) {
            if (mirrorState % 2 != 0) {
              xline[0] = wallWidth1 + totalDoorsWidth - scaledLineWidth[0] + 3.5
              xline[1] = 3.5
            }
            else {
              xline[0] = wallWidth1 + totalDoorsWidth + wallWidth2 - scaledLineWidth[0] - 3.5
              xline[1] = wallWidth1
            }

            xval[0] = grayStripeWidth
            xval[1] = grayStripeWidth / 2
            val[0] = -grayStripeWidth / 2
            val[1] = -grayStripeWidth / 2
          }
          if (numDoors == 4) {
            if (mirrorState % 2 != 0) {
              xline[0] = ((wallWidth1 + totalDoorsWidth + wallWidth2 - scaledLineWidth[0]) / 2)
              xline[1] = ((wallWidth1 + totalDoorsWidth + wallWidth2 - scaledLineWidth[1]) / 2)
            }
            else {
              xline[0] = ((wallWidth1 + totalDoorsWidth + wallWidth2 - scaledLineWidth[0]) / 2)
              xline[1] = ((wallWidth1 + totalDoorsWidth + wallWidth2 - scaledLineWidth[1]) / 2)
            }

            xval[0] = grayStripeWidth
            xval[1] = grayStripeWidth
            val[0] = -grayStripeWidth
            val[1] = -grayStripeWidth
            val[2] = -grayStripeWidth
            val[3] = -grayStripeWidth
          }
        }
        if (lineCount == 3) {
          if (mirrorState % 2 != 0) {
            xline[0] = wallWidth1 + totalDoorsWidth - scaledLineWidth[0] + 3.5
            xline[1] = 3.5
            xline[2] = 3.5
          }
          else {
            xline[0] = wallWidth1 + totalDoorsWidth + wallWidth2 - scaledLineWidth[0] - 3.5
            xline[1] = wallWidth1
            xline[2] = wallWidth1
          }

          val[0] = -grayStripeWidth + grayStripeWidth / 3
          val[1] = -grayStripeWidth + grayStripeWidth / 3
          val[2] = -grayStripeWidth + grayStripeWidth / 3
          xval[0] = grayStripeWidth
          xval[1] = grayStripeWidth * 2 / 3
          xval[2] = grayStripeWidth / 3

        }

      }
      else {
        xline[0] = ((wallWidth1 + totalDoorsWidth - scaledLineWidth[0]))
        xline[1] = ((wallWidth1 + totalDoorsWidth - scaledLineWidth[1]))
        xline[2] = ((wallWidth1 + totalDoorsWidth - scaledLineWidth[1]))
        xline[3] = ((wallWidth1 + totalDoorsWidth - scaledLineWidth[1]))
        if (lineCount == 1) {
          if (numDoors == 1) {
            val[0] = -grayStripeWidth
            xval[0] = grayStripeWidth
          }

        }
        if (lineCount == 2) {

          if (numDoors == 2) {
            val[0] = -grayStripeWidth
            val[1] = -grayStripeWidth
            xval[0] = grayStripeWidth
            xval[1] = grayStripeWidth
          }
        }
        if (lineCount == 3) {
          val[0] = -grayStripeWidth
          val[1] = -grayStripeWidth
          val[2] = -grayStripeWidth
          xval[0] = grayStripeWidth
          xval[1] = grayStripeWidth
          xval[2] = grayStripeWidth
        }
        if (lineCount == 4) {
          val[0] = -grayStripeWidth
          val[1] = -grayStripeWidth
          val[2] = -grayStripeWidth
          val[3] = -grayStripeWidth
          xval[0] = grayStripeWidth
          xval[1] = grayStripeWidth
          xval[2] = grayStripeWidth
          xval[3] = grayStripeWidth
        }
      }
    }
    else {
      if (wallSize[1] == "eq") {
        xline[0] = wallWidth1
        xline[1] = wallWidth1
        xline[2] = wallWidth1
        if (lineCount == 1) {
          if (numDoors == 1) {
            val[0] = -grayStripeWidth

          }

        }
        if (lineCount == 2) {

          if (numDoors == 2) {
            val[0] = -grayStripeWidth
            val[1] = -grayStripeWidth
          }
        }
        if (lineCount == 3) {
          val[0] = -grayStripeWidth
          val[1] = -grayStripeWidth
          val[2] = -grayStripeWidth
        }
        if (lineCount == 4) {
          val[0] = -grayStripeWidth
          val[1] = -grayStripeWidth
          val[2] = -grayStripeWidth
          val[3] = -grayStripeWidth
        }
      }
      else {
        xline[0] = ((wallWidth1 + totalDoorsWidth - scaledLineWidth[0]))
        xline[1] = ((wallWidth1 + totalDoorsWidth - scaledLineWidth[1]))
        xline[2] = ((wallWidth1 + totalDoorsWidth - scaledLineWidth[2]))
        xline[3] = ((wallWidth1 + totalDoorsWidth - scaledLineWidth[3]))
        if (lineCount == 2) {

          if (numDoors == 2) {
            val[0] = -1.5 * grayStripeWidth
            val[1] = -1.5 * grayStripeWidth
            xval[1] = grayStripeWidth / 2
          }
          if (numDoors == 3) {
            val[0] = -grayStripeWidth - grayStripeWidth / 3
            val[1] = -grayStripeWidth - grayStripeWidth / 3
            val[2] = -grayStripeWidth - grayStripeWidth / 3
            xval[1] = 1 / 3 * grayStripeWidth
            xval[2] = 2 / 3 * grayStripeWidth
          }
          if (numDoors == 4) {
            xval[0] = 0
            xval[1] = 0.5 * grayStripeWidth
            xval[2] = 0
            xval[3] = 0.5 * grayStripeWidth
            val[0] = -grayStripeWidth - 0.5 * grayStripeWidth
            val[1] = -grayStripeWidth - 0.5 * grayStripeWidth
            val[2] = -grayStripeWidth - 0.5 * grayStripeWidth
            val[3] = -grayStripeWidth - 0.5 * grayStripeWidth
          }
        }
        if (lineCount == 3) {
          if (numDoors == 3) {
            val[0] = -grayStripeWidth - grayStripeWidth / 3
            val[1] = -grayStripeWidth - grayStripeWidth / 3
            val[2] = -grayStripeWidth - grayStripeWidth / 3
            xval[1] = 1 / 3 * grayStripeWidth
            xval[2] = 2 / 3 * grayStripeWidth
          }
        }
        if (lineCount == 4) {
          val[0] = -grayStripeWidth * 5 / 4
          val[1] = -grayStripeWidth * 5 / 4
          val[2] = -grayStripeWidth * 5 / 4
          val[3] = -grayStripeWidth * 5 / 4
          xval[1] = 1 / 4 * grayStripeWidth
          xval[2] = 2 / 4 * grayStripeWidth
          xval[3] = 3 / 4 * grayStripeWidth

        }
      }
    }

  }

  const xOffset = (maxWidth - totalDoorsWidth - wallWidth1 - wallWidth2) / 2;

  const doors = Array.from({ length: numDoors }, (_, i) => {
    return (
      <DoorTop
        key={i} // Ключ тоже должен быть обратным
        x={xOffset + i * scaledDoorWidth + wallWidth1 - xval[i]}
        y={yOffset - door[i]?.position * doorHeight}
        width={doorWidthPlus + val[i]}
        height={doorHeight}
        doorWidth={doorWidth}
        grayStripeWidth={grayStripeWidth}
        vector={door[i]?.vector}
        mirrorState={mirrorState}
        total={-door[numDoors - i - 1]?.position * doorHeight + totalDoorsHeight}
      />
    );
  });

  const arrows = Array.from({ length: numDoors }, (_, i) => {
    return (
      <Arrow
        key={i} // Ключ тоже должен быть обратным
        x={xOffset + i * scaledDoorWidth + wallWidth1 - xval[i]}
        y={yOffset + totalDoorsHeight}
        width={doorWidthPlus + val[i]}
        doorWidth={doorWidth}
        grayStripeWidth={grayStripeWidth}
        vector={door[i]?.vector}
        mirrorState={mirrorState}
        total={-door[numDoors - i - 1]?.position * doorHeight + totalDoorsHeight}
      />
    );
  });

  const line = Array.from({ length: lineCount }, (_, i) => {
    return (
      <Line
        key={i} // Ключ тоже должен быть обратным
        x={xOffset + xline[i]}
        y={yOffset - linePositions[i] * doorHeight}
        width={scaledLineWidth[i]}
        height={doorHeight}
        lineWidth={lineWidth[i]}
        offset={20}
        one={one ? false : true}
        i={i + 1}
      />
    );
  });


  if (doors.length > 0) {
    return (
      <div className="container">
        <svg width={maxWidth} height={maxHeight} viewBox={`0 0 ${maxWidth} ${maxHeight}`} >
          <defs>
            <pattern id="lightHatch" patternUnits="userSpaceOnUse" width="20" height="20">
              <line x1="0" y1="0" x2="5" y2="5" stroke="lightgray" strokeWidth="1" />
            </pattern>
          </defs>
          <rect x={0} y={0} width={maxWidth} height={maxHeight} fill="url(#lightHatch)" />


          {/* <rect x={0} y={0} width={maxWidth} height={10 * (2 + parseInt(numDoors))} fill="lightgray" stroke='black' /> */}
          {line}
          {doors}
          {arrows}

          {/* Левый черный прямоугольник */}
          <rect x={xOffset} y={wallY1} width={wallWidth1} height={wallHeight1} fill="gray" stroke='black' />

          {/* Правый черный прямоугольник */}
          <rect x={xOffset + totalDoorsWidth + wallWidth1} y={wallY2} width={wallWidth2} height={wallHeight2} fill="gray" stroke='black' />
          {one && <>
            <SizeLineHorizontal
              x={xOffset + xline[0]}
              y={yOffset + totalDoorsHeight + 45}
              width={scaledLineWidth[0]}
              text={(lineCount > 1 ? "L треков  - " : "L трека  - ") + lineWidth[0]}
              lineLength1={totalDoorsHeight + 45}
              lineLength2={totalDoorsHeight + 45}
            />


          </>}

          <SizeLineHorizontal
            x={xOffset + wallWidth1}
            y={yOffset - 20}
            width={totalDoorsWidth}
            text={"L проема  - " + partitionWidth}
            textAboveLine={true}
            lineLength1={wallHeight1 + 20 + wallY1 - yOffset}
            lineLength2={wallHeight2 + 20 + wallY2 - yOffset}
          />
        </svg>
      </div>
    );
  }
}

export default PartitionTop;
