import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
  createRef,
} from "react";
import {
  HiMagnifyingGlass,
  HiCheckCircle,
  HiMiniCheckCircle,
} from "react-icons/hi2";
import { Link, useSearchParams } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import DataTable from "../../components/DataTable";
import Input from "../../components/UI/Input";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import SearchForm from "../../components/forms/SearchForm";
import { IoCloseOutline, IoTrashOutline } from "react-icons/io5";
import moment from "moment";
import { getCompanyAll } from "../../services/company";
// import socket from "../../config/socket";

const Companies = ({ config, setShowUser, showUser }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = createRef();
  const [company, setCompany] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const userColumns = [
    {
      name: "Название",
      selector: "title",
      width: 180,
      cell: (row) => (

        <Link to={"/company/" + row.id} className="d-flex align-items-center">
          <div className="text-transfer">{row.title}</div>
        </Link>
      ),
    },
    {
      name: "Телефон",
      selector: "phone",
    },
    {
      name: "Адрес",
      selector: "adress",
    },
  ];

  const getData = useCallback(async () => {
    getCompanyAll(searchParams)
      .then(
        (res) =>
          res &&
          setCompany((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setCompany((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>Компании</h5>
        <div className="search-box ms-5">
          <Input
            ref={inputRef}
            placeholder="Найти"
            className="w-100"
            onChange={(e) => {
              searchParams.set("text", e);
              setSearchParams(searchParams);
            }}
            defaultValue={searchParams.get("text")}
            onKeyDown={(e) => e === "Enter" && onSearch()}
          />
          {searchParams.get("text")?.length > 0 && (
            <Button
              className="btn-light ms-3"
              onClick={() => {
                searchParams.delete("text");
                setSearchParams(searchParams);
                onSearch();
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }}
            >
              <IoCloseOutline size={22} />
            </Button>
          )}
        </div>

        <Link to="create" className="btn-primary ms-5">
          Добавить
        </Link>


      </div>
    );
  }, [selected, searchParams, modalDelete]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  // const onDeleteSelected = useCallback(() => {
  //   deleteUser(selected.map((e) => e.item.id))
  //     .then(() => {
  //       setSelected([]);
  //       getData();
  //       NotificationManager.success("Выбранные пользователи успешно удалены");
  //       setModalDelete({ show: false, id: false });
  //     })
  //     .catch(() => NotificationManager.error("Ошибка при запросе"));
  // }, [selected]);

  if (company.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Компании" />
      <section className="box">
        <DataTable
          columns={userColumns}
          onChange={(items) => setSelected(items)}
          data={company.items}
          header={header}
          pagination={company.pagination}
        />
      </section>
      {/* <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить пользователя?
      </CustomModal> */}
    </Container>
  );
};

export default Companies;
