import React, { useRef, useEffect, useState } from 'react';

const SizeLineVertical = ({ x, y, height, width, text, size, textBelowLine }) => {
  // Проверка на корректность входных данных
  if (height <= 0 || width <= 0) {
    console.error("Height and width must be positive numbers.");
    return null;
  }

  const halfWidth = 15;
  const arrowSize = 5;
  const textRef = useRef(null);
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.offsetWidth);
    }
  }, [text]);

  const textX = halfWidth - (textBelowLine ? 0 : 10); // Смещение влево на 10px
  const textYOffset = textBelowLine ? height + 20 : height / 2;

  return (
    <g transform={`translate(${x}, ${y})`}>
      {/* Вертикальная линия */}
      <line
        x1={halfWidth}
        y1={0}
        x2={halfWidth}
        y2={height}
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      {!textBelowLine && <>
        {/* Стрелки */}
        <polygon
          points={`${halfWidth - arrowSize},${arrowSize} ${halfWidth + arrowSize},${arrowSize} ${halfWidth},0`}
          fill="black"
        />
        <polygon
          points={`${halfWidth - arrowSize},${height - arrowSize} ${halfWidth + arrowSize},${height - arrowSize} ${halfWidth},${height}`}
          fill="black"
        />
      </>}
      {/* Горизонтальные пунктирные линии */}
      <line
        x1={0}
        y1={0}
        x2={width}
        y2={0}
        stroke="black"
        strokeWidth="1"
      />
      <line
        x1={0}
        y1={height}
        x2={width}
        y2={height}
        stroke="black"
        strokeWidth="1"
      />

      {/* Текст */}
      {text && (
        <text
          x={textX}
          y={textYOffset}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={size}
          transform={`rotate(-90 ${textX}, ${textYOffset})`}>
          <tspan ref={textRef}>{text}</tspan>
        </text>
      )}

    </g>
  );
};

export default SizeLineVertical;
