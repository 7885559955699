import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import NotFound from "../pages/NotFound";
import Account from "../pages/account";
import CalcHistory from "../pages/calcHistory/index";
import CategoryEdit from "../pages/category/Edit";
import ConfigEdit from "../pages/configurator/Edit";
import Members from "../pages/member";
import MemberCreate from "../pages/member/Create";
import MemberEdit from "../pages/member/Edit";
import MemberTasks from "../pages/member/Tasks";
import Users from "../pages/user";
import UserCreate from "../pages/user/Create";
import UserEdit from "../pages/user/Edit";
import Companies from "../pages/company";
import EditCompany from "../pages/company/Edit";
import CompanyCreate from "../pages/company/Create";
import Orders from "../pages/calcHistory/Orders";

const AdminRouter = () => {
  const role = useSelector((state) => state.auth.user?.role);

  return (
    <Routes>
      <Route path="/">
        <Route path="account" element={<Account />} />
        <Route path="calcHistory" element={<CalcHistory status={0} />} />
        <Route path="calcHistory/:id" e element={<ConfigEdit />} />
        <Route path="order" element={<Orders />} />
        <Route path="order/:id" e element={<ConfigEdit />} />


        <Route path="company" element={<Companies />} />
        <Route path="company/:companyId" element={<EditCompany />} />
        <Route path="company/create" element={<CompanyCreate />} />

        <Route path="users" element={<Users />} />
        <Route path="user/:userId" element={<UserEdit />} />
        <Route path="users/create" element={<UserCreate />} />

        <Route path="members" element={<Members />} />
        <Route path="members/create" element={<MemberCreate />} />
        <Route path="member/:memberId" element={<MemberEdit />} />
        <Route path="member/:memberId/tasks" element={<MemberTasks />} />

        <Route path="catalog" element={<CategoryEdit />} />
        <Route path="configurator" element={<ConfigEdit />} />

      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminRouter;
