import React from 'react';
import SizeLineHorizontal from './SizeLineHorizontal';

const Line = ({ x, y, width, height, lineWidth, offset, one, i }) => {

  return (
    <g transform={`translate(${x}, ${y})`}>
      {/* Центральный прямоугольник */}
      <rect x={0} y={0} width={width} height={height} fill="white" stroke="gray" />
      {one && <>
        <SizeLineHorizontal
          x={0}
          y={y + 10}
          width={width}
          text={"L трека " + i + " - " + lineWidth}
          lineLength1={y + height + 10}
          lineLength2={y + height + 10}
        />


      </>}
    </g>
  );
};

export default Line;
