import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getCompanyAll = async (data) => {
  const response = await $authApi.get(apiRoutes.COMPANY, {
    params: data,
  });
  return response?.data;
};
const getCompany = async (id) => {
  const response = await $authApi.get(apiRoutes.COMPANY_ONE, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editCompany = async (data) => {
  // Создаем FormData для отправки файлов и данных
  const formData = new FormData();

  // Добавляем текстовые поля
  formData.append("id", data.id || "")
  formData.append("title", data.title || "");
  formData.append("phone", data.phone || "");
  formData.append("adress", data.adress || ""); // Из вашего кода следует, что поле адреса хранится в about

  // Добавляем файл если есть
  if (data.file) {
    formData.append("file", data.file); // Предполагая, что сервер ожидает поле "logo" для изображения
  }

  // Отправляем запрос
  const response = await $authApi.put(apiRoutes.COMPANY, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

const deleteCompany = async (id) => {
  const response = await $authApi.delete(apiRoutes.COMPANY, {
    data: { id },
  });
  return response?.data;
};

const createCompany = async (data) => {
  // Создаем FormData для отправки файлов и данных
  const formData = new FormData();

  // Добавляем текстовые поля
  formData.append("title", data.title || "");
  formData.append("phone", data.phone || "");
  formData.append("adress", data.adress || ""); // Из вашего кода следует, что поле адреса хранится в about

  // Добавляем файл если есть
  if (data.file) {
    formData.append("file", data.file); // Предполагая, что сервер ожидает поле "logo" для изображения
  }

  // Отправляем запрос
  const response = await $authApi.post(apiRoutes.COMPANY, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export { getCompany, getCompanyAll, editCompany, createCompany, deleteCompany };