import React, { useCallback, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import { createCompany } from "../../services/company";
import DragDropFile from "../../components/DragDropFile";
import ImageCropper from "../../components/Cropper";

const CompanyCreate = ({ config, setShowUser }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });
  const [image, setImage] = useState({
    show: false,
    data: false,
  });

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    createCompany(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      )
      .finally(() => {
        setBtnLoading(false)
        navigate(-1)
      })
  }, []);


  return (
    <Container fluid={true}>
      <Meta title="Добавление компании" />
      <section className="box">
        <h3 className="mb-4">Добавление компании</h3>
        <Card body>
          <Row>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Название"
                  name="title"
                  placeholder="Введите название (Необязательно)"
                  errors={errors}
                  register={register}
                  validation={{
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>

            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Номер телефона"
                  name="phone"
                  placeholder="+7(000)000-00-00"
                  mask="+7(999)999-99-99"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Адрес"
                  name="adress"
                  placeholder="Введите адрес"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={3} className="mb-4">
              <DragDropFile
                file={form?.media}
                onChange={(e) =>
                  setImage((info) => ({
                    show: !info.show,
                    data: e,
                  }))
                }
              />
              <ImageCropper
                file={image.data[0]}
                show={image.show}
                aspect={40 / 16}
                setShow={(e) =>
                  setImage((info) => ({ ...info, show: e }))
                }
                onComplete={(e) => {
                  e && setValue("file", e.file);
                  e && setValue("media", e.blob);
                }}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-primary"
              isValid={isValid}
              isLoading={btnLoading}
              disabled={btnLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить изменения
            </Button>
          </div>
        </Card>
      </section>
    </Container>
  );
};

export default CompanyCreate;
